@import '../../../containers/CRM/components/style/variables/colors';


.dashboard{
  margin-top: 100px;
  margin-left: 210px;
}

.scroller-div{
    scroll-margin-top: 30px;
    margin-bottom: 40px;
    scroll-padding-block: 30px;
    width: 1000px;
    height:600px
}

.modal-create-cluster{
  font-family: Poppins;
  .h6{
    font-family: Poppins;
  }
}
.list-menu
{
  margin-top: 30px;
  margin-bottom: 30px;
  .list-menu-item{
    min-width: 100px;
    background-color: #41E799;
    margin-right: 10px;
    padding: 3px;
  }
}

.back-button-to-dashboard{
  background-color: transparent;
  border: none;
  font-family: Poppins;
  margin-bottom: 5px;
  text-decoration: underline;
  color: #0b41b4;
  margin-left: 4em;
}

.Header-details-card{
border: "none";
background-color:#41E799;
width: 95vw;
margin-left: 2em;

}
.filter-list-span{
  margin-top: 10px;
  margin-right: 20px;
  background-color: $primaryColor;
  min-width: 75px;
  padding:5px;
  margin-bottom: 15px;
  
}
.dropdown-select-env{
  width:400px;
 margin-bottom: 20px;
 margin-top: -30px;

 .filter-icon{
  position:absolute;
  margin-left: 380px;
  margin-top: 10px;
}
 .chip{
  background-color: #1C1850;
  color: #FFF;
  border: 1px solid #1C1850;

}
.closeIcon{
  color: #000;
  font-size: large;
  width:15px;
height:15px;
  background-color: #1C1850;
   border: #1C1850 1px solid;
  border-radius: 20px;
}
}
.dropdown-select-columns{
  // background-color: #e9f5ed;
  width: 720px;
  color: #0b41b4;
  border: none;
  .searchWrapper{
    // border-radius: 10px;
  }
  .chip{
    background-color: #41E799;
    color: #000;
    border: 1px solid #11F64E;
  
  }
  .closeIcon{
    color: #000;
    font-size: large;
    width:15px;
height:15px;
    background-color: #0c9e33;
     border: #0c9e33 1px solid;
    border-radius: 20px;
  }
}
.server-id-button{

  width:fit-content;
  text-align: center;
  font-weight: 600;
  font-family: Poppins;
  color: #000;
  text-decoration: underline;
  padding: 5px;
  font-size: 20px;
  cursor: pointer;
  border: none;
  span:hover{
    color:#4ad39e ;
    font-weight: 700;
  }
}

.antd-table-cell{
    // scroll-behavior: smooth;
  font-size: small;
// overflow-x: scroll;

}


.data-table-container{
width: max-content;
}

.sub-overview-container{
  margin-left: -8px;
}

.light{
background-color: #fff;
font-size: small;
font-family: Poppins;
}

.dark{
    background-color: #f8f6f4;
    font-size: small;
    font-family: Poppins;
}


.ant-table-thead > tr > th {
    height: 50px;
    padding-top: 1px;
    padding:5px;
    padding-right: 2px;
     width:max-content;
    // min-width: 40px;
    font-size: 13px;
    text-align: center;
    color: #000;
    font-weight: 500;
    font-family: Poppins;
    background-color: #c5c3be;
    word-wrap: break-word;
    text-transform: uppercase;
  

  }

  .ant-table-tbody > tr > td {
    // color: aquamarine;
    text-align: center;
    text-transform: capitalize;
  }


.ant-table-cell{
    min-width: 105px;
    text-transform: capitalize;
}

.container-filter-button{
  // margin-top: -15px;
  // position: absolute;

}
.ant-table-filter-trigger{
  padding-right: 5px;
  margin-left: -3px;
  margin-right:25px;
  color: rgb(0, 0, 0);
  font-size: 12px;
  // border: #000 0.5px solid;
  // background-color: #cae4dc;
}
  
.reset-filter-button{
   position: absolute;
  // margin-top: -45.5%;
  margin-left: 84%;
  padding: 5px;
  // border-radius: 10px;
  background-color:#41e799 ;
  border:#2EEE7C 2px solid ;
   margin-top: 8.5%;
  z-index: 2;
  font-family: Poppins;
 
}


.apply-filter-button{
   position: absolute;
  z-index: 2;
  margin-left: 74%;
  background-color:#41e799 ;
  border:#2EEE7C 2px solid ;
  margin-top: 8.5%;
  width: fit-content;
  padding: 5px;
  font-family: Poppins;
 
}
.apply-filter-button-before{
  position: absolute;
 z-index: 2;
 padding: 5px;
 margin-left: 74%;
 background-color:#41e799 ;
 border:#2EEE7C 2px solid ;
 margin-top: 3.5%;
 width: fit-content;
 font-family: Poppins;
}
.clear-filter-button{
  position: absolute;
  z-index: 2;
  margin-left: 81%;
  // border-radius: 10px;
  background-color:#41e799 ;
  border:#2EEE7C 2px solid ;
  margin-top: -60px;
  width: fit-content;
  font-family: Poppins;
}



.apply-filter-button-home{
  position: absolute;

 z-index: 2;
 margin-left: 43%;
//  border-radius: 10px;
 background-color:#41e799 ;
 border:#2EEE7C 2px solid ;
 margin-top: 5px;
 width: fit-content;
 font-family: Poppins;
}
.clear-filter-button-home{
 position: absolute;
 z-index: 2;
 margin-left: 54%;
//  border-radius: 10px;
 background-color:#41e799 ;
 border:#2EEE7C 2px solid ;
 margin-top: 5px;
 width: fit-content;
 font-family: Poppins;
}


@media (min-width:1920px){
  .apply-filter-button{
   margin-left: 50%;
   margin-top: 4.5%;
   background-color:#41e799;
 }
  .apply-filter-button-before{
   margin-left: 50%;
   margin-top: 2%;
   background-color:#41e799;
  }
  .reset-filter-button{
   margin-left: 60%;
    margin-top: 4.5%;
 }
}

@media (min-width:2560px){
  .dropdown-select-columns{
    // background-color: #e9f5ed;
    width: 1020px;
  }
  .apply-filter-button{
   margin-left: 50%;
   margin-top: 3.5%;
   background-color:#41e799;
 }
  .apply-filter-button-before{
   margin-left: 50%;
   margin-top: 1.25%;
   background-color:#41e799;
  }
  .reset-filter-button{
   margin-left: 60%;
    margin-top: 3.5%;
 }
}





