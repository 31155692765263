@import "../../../components/style/paddings.scss";
@import "../../../components/style/variables/colors";
@import "../../../components/style/variables/dimensions";




.parentContainer
{
  width: 400px;
}

.profile-container-row{
  // background-color: aquamarine;
}

.profile-menu-column {
  margin-right: $margin-small;
  position: fixed;
  height: unset;
  width: 22vw;
  top: 0;
 // background-color: aqua;
}

.profile-content-row {
  display: flex;
  flex-direction: row;
   margin-left: 20px;
//  margin-right: auto;
  width: 95vw;
  // background-color: #fff;
  
}

.profile-layout-right-content {
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: 0px 0px 0px 0px;
  // border-left: 1px solid rgba(0, 0, 0, 0.06);
  background-color: transparent;
  width: fit-content;
  margin-left: -50px;
  //  border: black 1px solid;
}

.profile-layout-right-content-column {
  flex: 1;
 
}

.profile-layout-mobile-footer-row {
  display: none;
}

@media (max-width: 900px) {
  .profile-content-row {
    display: flex;
    flex-direction: column;
    margin-left: unset;
    margin-top: 100px;
  }

  .profile-menu-column {
    margin-right: unset;
    position: fixed;
    height: 100px;
    width: 100%;
    top: 0;
    background-color: white;
    z-index: 1;
  }

  .profile-layout-right-content {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .profile-layout-mobile-footer-row {
    display: inline-flex;
    justify-content: center;
    width: 100%;
  }
}
