@import "../../../components/style/variables/colors";


.registration-page-container {
  min-height: 90vh;
  display: flex;
  flex-direction: row;
  margin-left:auto;
  margin-right:auto;
  &__left-section {
    width: 40%;
    border: #DD0C4E 1px solid;
    height:fit-content;
    margin-top: 10px;
    margin-left:auto;
    margin-right:auto;
    .logo-registration{
      width:130px;
      margin-left: auto;
      margin-right: auto;
      margin-top: -25px;
    }
    &__header {
      margin-top: 20px;
      margin-left: 20%;
      // font-size: 20px;
      @media screen and (max-width: 600px) {
        margin-left: 10%;
      }
      &__title {
        font-size: 1.5rem;
        font-weight: 600;
        @media screen and (max-width: 600px) {
          font-size: 1.875rem;
        }
        @media screen and (min-width: 600px) and (max-width: 800px) {
          font-size: 1.2rem;
        }
        @media screen and (min-width: 800px) and (max-width: 1200px) {
          font-size: 1.6rem;
        }
      }
      &__sub-title {
        color: #8f8f8f;
      }
    }
    &__form {
      margin-top: 3%;
      display: flex;
      flex-direction: column;
      align-items: center;
      &__form-container {
        width: 60%;
        @media screen and (max-width: 600px) {
          width: 75%;
        }
        @media screen and (min-width: 600px) and (max-width: 1200px) {
          width: 70%;
        }
      }

      .dropdownlabel{
        font-size: medium;
        font-family: Poppins;
      }
      .dropdown-input{
        font-size: medium;
        color: #D9D9D9;
      // width:max-content;
       padding-left: 20px;
       padding-right: 100px;
        border: #D9D9D9 0.5px solid;
        border-radius: 4px;
        padding: 5px;
        .DownOutlined{
          margin-left:75px ;
          padding-left:70px ;

          @media screen and (min-width:1440px){
            margin-left:105px ;
            padding-left:80px ;
          }
          @media screen and (min-width:1920px){
            margin-left:220px ;
            padding-left:80px ;
          }
        }
        span{
          width: 100px;
        
          }
         
        
        
      }
     

      &__text-field {
        width: 100%;
        border-radius: 4px;
        min-height: 2.15rem !important;
        color: black;
        @media screen and (min-width: 1920px) {
          min-height: 3.125rem !important;
          font-size: 1.2rem !important;
        }
      }

      &__text-field > input{
        color: black !important;
        font-size: 1rem;
        @media screen and (min-width: 1920px) {
          min-height: 3.125rem !important;
          font-size: 1.2rem !important;
        }
      }
    

      &__label {
        margin: 5px 0px;
        font-weight: 500;

      }
      &__label:nth-child(4) {
        margin-top: 15px;
      }
      &__label > div {
        padding-bottom: 1px !important;
        font-size: 18px !important;
        @media screen and (min-width: 1920px) {
          font-size: 24px !important;
        }
      }
      &__btn {
        width: 100%;
        margin-top: 1%;
        color: white;
        background-color: #DD0C4E;
        border-radius: 5px;
        @media screen and (min-width: 1920px) {
          min-height: 3.1255rem !important;
          font-size: 1.2rem !important;
        }
        &:hover {
          background: #1C1850;
          border-color: #1C1850;
          color: #fff;
        }
      }
      &__na-acc {
        margin-top: 18%;
        font-size: 1rem;
        &__text {
          color: #8f8f8f;
        }
        &__link {
          padding: 0;
          font-weight: 600;
          color: black;
          @media screen and (max-width: 600px) {
            margin-top: 1%;
          }
        }
      }
    }
    @media only screen and (max-width: 600px) {
      width: 100%;
    }
  }
}
@media only screen and (max-width: 600px) {
  .registration-page-container {
    .right-section {
      display: none;
    }
    .left-section {
      width: 100%;
    }
  }
}
