@import "../../../components/style/variables/colors";

.login-page-container {
  height: 375px;
  display: flex;
  margin-top: 8%;
  flex-direction: row;
  @media screen and (max-width: 900px) {
    margin-top: 25%;
    }
  @media screen and (min-width: 1320px) {
    margin-top: 12%;
    }
  @media screen and (min-width: 1920px) {
      margin-top: 12%;
      }
  @media screen and (min-width: 1920px) {
  margin-top: 12%;
  }
  @media screen and (min-width: 2020px) {
    margin-top: 18%;
    }

  .logo-in-header{
    position: absolute;
    margin-left: 46%;
    // margin-top: -110px;
    margin-bottom: 10px;
    
  }
  
  &__left-section {
    width: 350px;
    height:400px;
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
    margin-top: -0px;
    
   
    // background-color: #1dd1a1;
    border-radius: 3px;
    border: 1px #DD0C4E solid;
    &__header {
      margin-top: 50px;
      margin-left: 20px;
      margin-bottom: 10px;
      font-size: 1rem;
      @media screen and (max-width: 600px) {
        margin-left: 10%;
      }
      &__title {
        font-size: 1.625rem;
        margin-left: 20px;
        font-weight: 600;
        margin-top: -10px;
        @media screen and (max-width: 600px) {
          font-size: 1.688rem;
        }
      }
      &__sub-title {
        color: #8f8f8f;
        margin-left: 20px;
        font-size: 1rem;
        line-height: 24px;
        margin-bottom: 25px;
        @media only screen and (max-width: 600px) {
          font-size: 1rem;
          line-height: 1.375rem;
        }
        @media only screen and (min-width: 600px) and (max-width: 1200px) {
          font-size: 0.8rem;
          line-height: 1.625rem;
        }
      }
    }

    .login-footer-position{
      margin-top: 50px;
    }

    &__form {
      margin-top: 2%;
    
      display: flex;
      flex-direction: column;
      align-items: left;
    //  It is  // border: #1dd1a1 1px;
      
      &__form-container {
        width: 50%;
        // background-color: #cef0e7;
        
        @media screen and (max-width: 600px) {
          width: 80%;
        }
        @media screen and (min-width: 600px) and (max-width: 1200px) {
          width: 65%;
        }
      }
      .ant-form-item-explain{
        margin-top: -5px;
        font-size: small;
      }
      .ant-input{
        width: 260px;  
        height:30px;
        border: #DD0C4E 1px solid;
        margin-top: -10px;
        margin-left: 35px;
        @media screen and (min-width: 1920px) {
          // min-height: 3.625rem !important;
          font-size: 0.9rem !important;
          margin-bottom: 20px;
        }
      }
      &__text-field {
      
        min-height: 2.188rem !important;
        margin-top: 10px;
        margin-bottom: -20px;
        @media screen and (min-width: 1920px) {
          margin-bottom: 0px;
        }
      }
      .ant-form-vertical .ant-form-item-label > label{
        margin-left: 30px;
      }
      &__label {
        // align-self: flex-start;
        font-weight: 600;
        font-size: 1rem;
       
      }
      .ant-btn {
        background-color: #DD0C4E;
        border: $primaryColor;
        width: 260px;
        height:30px;
        margin-left: 35px;
     
      
       
        @media screen and (min-width: 1920px) {
          // min-height: 3.625rem !important;
          // font-size: 1.2rem !important;
          height:30px;
          margin-top: 0px;
          
        }
      }
      &__btn-1 {
        width: 200px;
        margin-top: -21px;
        color: white;
        background-color: #DD0C4E;
        border-radius: 3px;
        margin-top: -10px;
        

        @media screen and (max-width: 600px) {
          margin-top: 0.938rem !important;
        }
        @media screen and (min-width: 600px) and (max-width: 1400px) {
          margin-top: 0.75rem !important;
           width: 170px;
        }
        @media screen and (min-width: 1920px) {
          // min-height: 3.625rem !important;
          margin-top: -10px;
          font-size: 1.0rem !important;
        }
        &:hover {
           background: none;
          border:1px solid blue;
          color: #DD0C4E;
        }
      }

     .google-button-div{
      height:30px;
      element.style {
        height: 30px;
    }
      button{
        background-color: #FFF;
        width:260px;
        margin-left: 35px;
        height:30px;
        border: #DD0C4E 1px solid;
        border-radius: 3px;
        &:hover {
          background: #FFF;
          border-color: blue ;
          color: #DD0C4E;
        }
      }
      &__btn-2 {
          background-color: #FFF;
           width:200px;
           height:30px;
          // position: absolute;
          // margin-left: 105px;
        &:hover {
          background: $primaryColor;
          border-color: $primaryColor;
          color: $primaryColor;
        }
      }

    .google-signIn-img{

      width: 20px;
      background-color: #FFF;
      height:20px;
      margin-left: -5px;
      margin-right: 10px;
      z-index: 2;
     
  @media screen and (min-width: 1920px) {
    // min-height: 3.625rem !important;
   
    //  margin-left: -105px;
   
  }
}

     }
     .login-footer-position{
      margin-top: 0px;
      // background-color: #1dd1a1;
    }
    
      &__na-acc {
        margin-top: 13%;
        font-size: 1rem;
        &__text {
          color: #8f8f8f;
        }
        &__link {
          padding: 0;
          font-weight: 600;
          color: black;
          @media screen and (max-width: 600px) {
            margin-top: 1%;
          }
        }
        @media screen and (min-width: 600px) and (max-width: 1200px) {
          font-size: 0.8rem !important;
        }
        @media only screen and (max-width: 600px) {
          margin-top: 8% !important;
          font-size: 1rem;
        }
      }
    }
    @media only screen and (max-width: 600px) {
      width: 100%;
    }
  }
}
@media only screen and (max-width: 600px) {
  .login-page-container {
    .right-section {
      display: none;
    }
    .left-section {
      width: 85%;
    }
  }
}

// .ant-btn{
//   width: fit-content;
// }

