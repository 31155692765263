@import "../../style/variables/colors";

.menu-item {
  cursor: pointer;
  margin:10px 0px;
}

.menu-item-disabled {
  cursor: not-allowed !important;
  color: $darkGray !important;
}

.menu-item-selected {
  color: $white;
}