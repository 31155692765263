@import "../../../components/style/variables/colors";

.line-break {
    border-right: 2px solid rgba(0, 0, 0, 0.06);
    // background-color: aqua;
}
.headerPricing {
    display: flex;
    // background-color: aquamarine;
    border-top: 2px solid rgba(0, 0, 0, 0.06);
    border-right: 2px solid rgba(0, 0, 0, 0.06);
    border-bottom: 2px solid rgba(0, 0, 0, 0.06);
    border-left: 0.5px solid rgba(0, 0, 0, 0.06);
    @media screen and (min-width: 320px) and (max-width: 900px) {
        border-right: 0px !important;
        border-left: 0px !important;
    }
}

.subHeaderPricing {
    font-family: "Poppins";

    padding: 15px;
    border-right: 2px solid rgba(0, 0, 0, 0.06);
    min-width: 22%;
    @media screen and (max-width: 600px) {
        min-width: 50%;
        border-right: 0px !important;
    }
}
.pricing-card-cost-freq {
    font-family: sans-serif;
    font-size: 1.2rem; //here
    font-weight: 100;
}
.bottomTopTitle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 10%;
}
.bottomTopTitle-children {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    @media screen and (min-width: 320px) and (max-width: 900px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}
.ant-btn-primary {
    background-color: #41e799;
    border: #41e799;
}

.pricing-dsc-container {
    li {
        font-family: Poppins;
        margin-top: 10px;
    }
    padding: 2%;
    width: 80vw;
    min-height: 105vh;
    margin-left: 220px;
    margin-top: -2em;

    // border: #000 1px solid;
    @media screen and (min-width: 1920px) {
        margin-left: 80px;
        width: 80vw;
    }

    &__bottom-content {
        
        &__title {
            font-family: "Poppins";
            font-size: 1.8rem;
            font-weight: 600;
            margin-left: 5px; 
            margin-left: auto;
            margin-right: auto;
            margin-top: 4em;

            @media screen and (min-width: 320px) and (max-width: 500px) {
                margin-top: 35%;
                margin-left: -175px; 
                font-size: 18.5px;
                width: 88vw;

                // background-color: #41e799;
            }
            @media screen and (min-width: 500px) and (max-width: 700px) {
                margin-top: -2% !important;
                margin-left: -80px; 
                font-size: 20px;
                width: 90vw;
                // background-color: #41e799;
            }
            @media screen and (min-width: 700px) and (max-width: 900px) {
                margin-top: 2% !important;
                margin-left: -30px; 
                font-size: 20px;
                width: 95vw;
                // background-color: #41e799;
            }
        }
        &__title__one {
            font-family: "Poppins";
            font-size: 1.8rem;
            font-weight: 600;
            margin-left: 5px;

            color: $primaryColor;
            @media screen and (min-width: 320px) and (max-width: 900px) {
                background-color: #e9e9e9;
                margin-left: -102px; 
                margin-top: -10% !important;
            }
        }
        &__subtitle {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 600;
            font-size: 1rem;
            line-height: 24px;

            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0px auto;
            @media screen and (min-width: 320px) and (max-width: 500px) {
                
                 margin-left: -150px; 
                width: 80vw;
                margin-bottom: 4em;
               
        }
        @media screen and (min-width: 520px)and (max-width: 900px)   {
            margin-left: -110px; 
            margin-bottom: 3em;
    }
        }
        &__card-container {
            display: flex;
            // background-color: #41E799;
            // justify-content: space-evenly;
            align-items: start;
            margin-left: -30px;
            width: 80vw;
            margin-top: -3em;
            border: none;
            // border: 1px black solid;
            //  background-color: antiquewhite;
        }

        .category-pricing {
            font-size: large;
            // position: absolute;
             margin-top: -25px;
            background-color: #ffffff;
            border: none;
            width: 300px;
            text-align: center;
            font-size: larger;
            margin-left: -120px;
            margin-right: 210px;
            @media (min-width: 1300px) {
                margin-left: -65px;
                margin-right: 150px;
            }
            @media (min-width: 1400px) {
                margin-left: -65px;
                margin-right: 150px;
            }
            @media (min-width: 1620px) {
                margin-left: -10px;
                margin-right: 125px;
            }
            @media (min-width: 1900px) {
                width: 450px;
                margin-left: 20px;
                margin-right: 20px;
            }
            @media (min-width: 2560px) {
                // width: 450px;
                // margin-left: 100px;
                // margin-right: 90px;
            }
            .ant-card-head-title {
                color: rgb(19, 15, 24);
                height: 40px;
                padding: 5px;
                text-align: center;
                margin-top: 5px;
                font-size: 18px;
                font-family: Poppins;
                border: none;
                font-weight: 500;
                background-color: #e2fdf0;
                //   background-image: radial-gradient(  rgb(248, 248, 248) 10%,rgb(160, 240, 197) 65%);
                //   background-image: linear-gradient(to right,#FFF,rgb(241, 195, 195));
            }
            .pic2-price-category {
                width: 30px;
                height: 40px;
                margin-top: -68px;
                position: absolute;
                margin-left: -42%;
                @media (min-width: 1900px) {
                    margin-left: -42%;
                }
            }
            .ant-card-head {
                //   background-color: #97f1c1;
                border: none;
            }
        }
    }
}

.common-features-table {
    // width: 1040px;
    width:86.75vw;
    height: max-content;
    // border: #D1D1D1 1px solid;
    // margin-left:130px;
    margin-left: -96px;
    .dark-table-plan{
        td{
            color:#DCDCDC;
        }
        tr {
            background-color: #5e5c5c;
           color:#fff;
        }
        tr:nth-child(even) {
            background-color: #444142;
            color:#fff;
        }
    }
    table {
        width: 100%;
        font-family: Poppins;
        // border: #D1D1D1 0.5px solid;
        font-size: 14px;
    }
    tr {
        margin-top: 5px;
        //  border-top: #D1D1D1 0.5px solid;
        padding: 10px 0px 10px 0px;
        height: 45px;
    }
    tr:nth-child(even) {
        background-color: #e9e9e9;
    }

    th {
        color: #fff;
    }
    td {
        color: #3a3a3a;
    }
    .table-data {
        width: 200px;
        text-align: center;
        // background-color: #41E799;
        // padding-left: 70px;
        // border: #000 1px solid;
    }
}

.toggle-btn-container {
    width: 70%;
    margin: 5% auto 0 auto;
    &__btn {
        color: $primaryColor;
        border-color: $primaryColor;
        font-weight: 600;
        font-size: 1rem;
        &:focus {
            color: $primaryColor;
            border-color: $primaryColor;
        }
    }
}

@media (max-width: 900px) {
    .pricing-dsc-container {
        padding: 10px;
        margin-top: -20px;
        &__bottom-content {
            margin-left: 20px;
           
            &__card-container {
                flex-direction: column;
                width: 200px;
                margin-left: -10px;
            }
        }
    }
    .toggle-btn-container {
        width: 100%;
    }
}

@media (min-width: 1300px) {
  
    .pricing-dsc-container{
        margin-left: 200px;
    }
     .common-features-table {
       width:80.5vw;
        margin-left: -45px;
    }
}
@media (min-width: 1400px) {
    // .category-pricing {
    //     width: 300px;
    // }
    .common-features-table {
        width:76.75vw;
        margin-left: -40px;
    }
    .pricing-dsc-container{
        margin-left: 245px;
    }
}

@media (min-width: 1520px) {
    // .category-pricing {
    //     .ant-card {
    //         width: 350px;
    //         // background-color: aquamarine;
    //     }
    // }
    .pricing-dsc-container{
        margin-left: 280px;
    }
    .common-features-table {
        // width: 99%;
        width:72vw;
        margin-left: -40px;
    }
}
@media (min-width: 1620px) {
    .pricing-dsc-container{
        margin-left: 290px;
    }
   
    .common-features-table {
         width: 1160px;
        margin-left: 10px;
    }
}
@media (min-width: 1920px) {
    .common-features-table {
        width: 1330px;
        margin-left: 40px;
        table {
            font-size: 22px;
        }
        td{
            padding-top: 15px;
            padding-bottom: 15px;
        }
    }
    
    .pricing-dsc-container{
        margin-left: 290px;
        
        &__bottom-content {
        
            &__title {
                margin-left: -100px;
            }
            &__subtitle {
                margin-left: 500px;
            }


        }
    }
}
@media (min-width: 2560px) {
    // .category-pricing {
    //     .ant-card {
    //         width: 350px;
    //         background-color: aquamarine;
    //     }
    // }
    .common-features-table {
        // width: 1750px;
        margin-left: 22px;
        // table {
        //     font-size: 22px;
        // }
    }
    .pricing-dsc-container{
        margin-left: 600px;
        // margin-left: auto;
        // margin-right: auto;
        &__bottom-content {
        
            &__title {
                margin-left: -500px;
            }
            &__subtitle {
                margin-left: 520px;
            }


        }
    }

 
}

@media (max-width: 700px) {
    .pricing-dsc-container{
margin-top: -75px;
    }
    .common-features-table {
        width: 70vw;
        margin-left: -115px;
        // td{
        //     padding-left: -10px;
        // }
    }
    .category-pricing {
        margin-left: -15px;
        // margin-left: 22%;
        // margin-left: -35px; 
    }
    
    
}
@media (min-width: 700px) and (max-width: 900px) {
    .common-features-table {
        width: 65vw;
        margin-left: -105px;
        font-size: 12px;
         
    }
    .category-pricing {
        margin-left: 26%;
       
    }
    
    
}

.footer-plan-pricing {
    margin-top: -20px;
    @media (min-width: 1920) {
        margin-top: -150px;
    }
}


