@import "../../../components/style/paddings";
@import "../../../components/style/variables/colors";


.footer-row {
  display: inline-flex;
  flex-direction: row;

  .gray-text {
    color: $darkGray;
  }
}

