.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  height: 100vh;
  width: 60px;
  // box-shadow: 0 1px 30px 1px rgba(0, 0, 0, 0.11);
  padding-top: 80px;
  transition: transform 0.3s, width 0.3s;
  transform: translateX(calc(-100% - 20px));
  background-color: #D0D0D0 ;

  // @include themify($themes) {
  //   background: themed('colorBackground');
  // }

  // a {
  //   display: block;
  // }

  &.sidebar--show {
    transform: translateX(0);
    width: 200px;
    position: absolute;
    height: 100vh;
    background-color: #b1c3c8;

    .sidebar__back {
      display: block;
    }
  }
}

.sidebar__back {
  height: 100%;
  width: 100vw;
  position: absolute;
  display: none;
  background: transparent;
  border: none;
}

.sidebar__link-active {

  .sidebar__link {

    &:before {
      opacity: 1;
    }
  }
}

.sidebar__scroll {
  width: 40px;

  & > div {
    height: calc(100vh - 60px);
  }

  .scrollbar-track {

    &.scrollbar-track-y {
      width: 2px;
      margin-right: 3px;
    }

    &.scrollbar-track-x {
      display: none !important;
    }
  }

  .scrollbar-thumb {
    opacity: 0.3;
    width: 5px;
  }
}

.sidebar__content {
  padding-top: 15px;
  height: 100%;
  // overflow: auto;

  & > div:last-child {
    width: 4px !important;

    div {
      transition: height 0.3s;
      opacity: 0.52;
    }
  }
}

.sidebar__block {
  
  padding: 15px 0;

  @media (min-width:1440px) {
    margin-top: 10vh;
  }
  @media (min-width:1640px) {
    margin-top: 16vh;
  }

  @include themify($themes) {
    border-bottom: 1px solid themed('colorBorder');
  }

  &:last-child {
    border: none;
  }
  span{
   color: #1d8baa;   
  }
  button{
    margin-left: 5px;
    margin-top: 3px;
    font-size: 20px;
   
    // background-color: #1d8baa;
    .icon-menu{
     color: #1C1850;
    //  width: 1.5rem;
    font-size: 1.75rem;
     
    }
    
    .active-icon-menu{
      font-size: 2.25rem;
      color:#DD0C4E;
      font-weight: bold;
    // border:2px solid #DD0C4E
    }

    &:hover {
      // padding: -5px;
      //    border: #41e799 solid 1px;
      background-color: #acadb1;
    }
  }
}

.sidebar__link {
  height: 36px;
  width: 250px;
  transition: all 0.3s;
  position: relative;
  cursor: pointer;
  display: flex;
  padding: 11px 20px;
  overflow: hidden;
  background: transparent;
  border: none;

  @include themify($themes) {
    color: themed('colorText');
  }

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 5px;
    height: 70%;
    width: 100%;
    // border-radius: 50px;
     background: #41e799;
    // background-image: linear-gradient(#c3ecda,#93e9c1);
    opacity: 0;
    transition: all 0.3s;
  }



  p {
    position: absolute;
    left: 43px;
    width: 160px;
    transition: left 0.3s;
    top: 50%;
    transform: translateY(-50%);
    font-family: Poppins;
    
  }

  .sidebar__link-icon {
    margin-right: 10px;
    margin-top: 2px;
    font-size: 14px;
    line-height: 13px;
    color: #000;
    transform: translateY(-50%);
    
  }

  &:hover {
    text-decoration: none;

    @include themify($themes) {
      background-color: themed('colorHover');
    }

    &:before {
      opacity: 1;
    }
  }
}

.launch-spike-button{
  
  margin-top: 10px;
  // background-image: radial-gradient(  #caf7e4 10%,#95f3c1 65%);
  text-align: center;
  width: fit-content;
  margin-left: 20px;
  // margin-left: auto;
  // margin-right: auto;
  padding: 5px;
  color: #000;
  font-family: Poppins;
  border-radius: 7%;
span{
  margin-right:5px;
}
  p{
    margin-top: 5px;
    font-size: 18px;
    color: #000;
    font-weight: 500;
  }
}

.sidebar__link-title {
  margin: 0;
  font-size: 14px;
  line-height: 16px;
  position: relative;
  display: flex;
  // visibility: hidden;
  // &:hover  {
  //   visibility:visible;
  // }
}

.sidebar__link-icon {
  margin-right: 10px;
  margin-top: -2px;
  font-size: 14px;
  line-height: 13px;
  color: #000;

}

.sidebar__submenu {
  transition: height 0.5s 0s, padding 0.5s 0s, opacity 0.4s 0.1s;
  padding: 15px 0;

  & .sidebar__submenu {
    margin-bottom: 0;
    padding-bottom: 0;
    padding-top: 0;

    .sidebar__link {
      padding-left: 53px;

      p {
        left: 53px;
      }
    }
  }

  @include themify($themes) {
    background-color: themed('colorHover');
  }

  .sidebar__link {
    padding-left: 43px;

    &:hover {
      @include themify($themes) {
        background-color: themed('colorBackground');
      }
    }
  }

  .sidebar__link {
    display: block;
  }
}

.sidebar__category-icon {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 10px;
  line-height: 14px;
  opacity: 1;
  transition: opacity 0.5s 0.2s, transform 0.3s;
  // color: $color-gray;
color: #1d8baa;
}

.sidebar__link-badge {
  width: 26px;
  height: 14px;
  background-color: $color-red;
  font-size: 8px;
  font-weight: 400;
  padding: 2px;
  margin-left: 5px;
  line-height: 9px;
  position: relative;
  text-transform: uppercase;
  border-radius: 7px;

  span {
    position: absolute;
    left: 0;
    top: 3px;
    width: 26px;
    text-align: center;
  }
}

.sidebar__wrapper--desktop {
  display: none;
}

.sidebar__category-wrap {

  &.sidebar__category-wrap--open {
    & > .sidebar__category {
      .sidebar__category-icon {
        transform: rotate(90deg);
      }
    }
  }
}

.sidebar__category-new {
  height: 6px;
  width: 6px;
  border-radius: 50%;
  top: -3px;
  display: block;
  margin-left: 5px;
  background: $color-red;
}

@media screen and (min-width: 276px) {
  .sidebar--collapse {
    .sidebar__category-wrap:hover {
      .sidebar__submenu-wrap {
        display: inherit !important;
      }
    }
  }

  .sidebar {
    transform: translateX(0);

    &.sidebar--no-desktop {
      transform: translateX(calc(-100% - 20px));

      &.sidebar--show {
        transform: translateX(0);
      }

      .sidebar__wrapper--mobile {
        display: block;
      }
    }

    &.sidebar--collapse {
      width: 60px;
      overflow: visible;
      text-align: center;
      justify-content: center;
      .sidebar__scroll, .sidebar__content {
        width: 55px;
        overflow: visible !important;
        transition: width 0.3s;

      }

      .sidebar__link {
        @include themify($themes) {
          background: themed('colorBackground');
        }
      }

      .sidebar__submenu {
        padding: 0 0 15px 0;
        transition: 0s;
      }

      .sidebar__block > .sidebar__category-wrap:hover > .sidebar__category {
        width: 240px;
        .sidebar__category {
          @include themify($themes) {
            background: themed('colorHover');
          }
          &:before {
            opacity: 1;
          }
        }
      }
      .sidebar__submenu-wrap {
        position: absolute;
        left: 55px;
        width: 0;
        transition: 0.3s;
        display: none;
        .sidebar__link {
          width: 185px;
          padding-left: 15px;

          p {
            position: relative;
            left: 0;
            animation: none;
          }
        }

        .sidebar__submenu-wrap {
          position: relative;
          left: 0;

          .sidebar__link {
            padding-left: 30px;
          }
        }
      }

      .sidebar__link {
        overflow: hidden;
        width: 55px;
        background-color: transparent;

        p {
          position: absolute;
          left: 70px;
          width: 160px;
        }

        &:hover {

          @include themify($themes) {
            background: themed('colorHover');
          }
        }
      }

      .sidebar__category-icon {
        opacity: 0;
        transition: opacity 0s;
      }

      .scrollbar-track.scrollbar-track-y {
        margin-right: 188px;
      }

    }
  }

  .sidebar__wrapper--desktop {
    display: block;
  }

  .sidebar__wrapper--mobile {
    display: none;
  }

}

@media screen and (min-width: 992px) {

  .sidebar.sidebar--no-desktop {
    transform: translateX(0);
    display: none;
  }
}

.button-logout{
  width:100%;
  justify-content: center;
  background-color: #FFF;
  
  svg{
    padding-top: 2px;
    vertical-align:text-top;
    color: #000;
  }
  button{
    width:100%;
    border: none;
    padding-left: 20px;
    text-align: left;
    font-family: Poppins;
    font-size: 14.5px;
    background-color: #FFF;
    color:#646778;
    &:hover {
      background-color: #41e799;
    }
  }
  background-color: #FFF;
 
}

.search-button-side-bar{
  border: none;
  color:#000;
  padding-left: 20px;
  font-size: 14px;
  font-family: 'Poppins';
  background-color: #41e799;
  a{
    margin-top: -2px;
    color:#000;
    font-weight: 800;
    background-color: #41e799;
    span{

    }
  }
  h5{
    color:#646778;
    position: relative;
  }

}

