@import '../../../components/style/variables/colors';

.parent {
  // max-height: 950px;
  padding: 15px;
  margin-top: -50px;
  height:350px;
  // background-color: #303030;
  // border-radius: 8px;
  width: 300px;

  .ant-typography{
    color:#000
  }
  .ant-btn-primary{
    background-color: #41E799;
     border: #41E799;
    color: #000;
    height:32px
}
.ant-btn{
 // background-color: #41E799;
  // border: #41E799;
  color: #FFF;
  height:38px;
  border: none;
  font-family: Poppins;
  border-radius: 3px;
background-color: #DD0C4E;
&:hover{
  background-color: #1C1850;
}
  // background-image:linear-gradient(to top , #29C2B6, #2CD97B );
}
  @media screen and (min-width: 320px) and (max-width: 900px) {
    max-height: 553px;
  }
}
.subscribe-button-price-categories{
  background-color: $primaryColor;
  color: #303030;
  border: none;
  margin-top: 15px;
  width:260px;  

  &__button-top-margin-mgd{
    border: none;
    margin-top: 0px;
    width:260px;
    border-radius: 5px;
    // :hover{
    //   background-color: yellow;
    // }
  }
  &__button-top-margin-self{
    margin-top: 0px;
    width:260px;
   
    .ant-btn{
      height: 50px;
      border-radius: 5px;
    }
  }
  &__button-top-margin-saas{
    margin-top: 0px;
   
    width:260px;
    .ant-btn{
      height: 50px;
     
    }
  }
}

.title-pricing-card{
position: absolute;
width:109.5%;
background-color: #1C1850;
margin-top: -1.3em;
margin-left:-0.9em ;
height:4.5em;
color:#fff;
}
 //here
.companyName {
  color: #000;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2%;
  width: 100%;
  font-family: "Poppins", sans-serif;
}

.companyName b {
  color: #000;
  font-weight: 700;
}

.cardStatus {
 
  height: 25px;
  width: 80px;
  display: flex;
  padding-left: 6px;
  justify-content: space-evenly;
  text-align: center;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 600;
  border-radius: 4px;
  line-height: 29px;
  margin-bottom: 5px;
}

.plan {
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
}

.days {
  color: #000;
  font-size: 0.9rem;
  font-weight: 700;
}

.days-left {
  color: #000;
  font-size: 0.8rem;
}

.offers {
  color: #000;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-wrap: wrap;
  margin-top: -10px;
  font-family: Poppins;
  // font-size: 10px;


}

.index {
  display: flex;
  flex-wrap: nowrap;
  white-space: pre-line;
  padding: 3% 0px;
  font-weight: 400;
  font-size: 14px;
  font-family: "Poppins";
  text-align: left;
  // font-style: normal;
  // font-weight: normal;
}

.pricing-card-cost-title {
  // display: flex;
  // flex-direction: row;
  // justify-content: flex-start;
  height: 25px;
  width: 100%;
  margin-bottom: 10%;
   margin-top: -6%;
  text-align: center;
  font-family: Poppins;
  color:#000;
  background-color: #29C2B6;
  &__margin-ss{
    color: #000;
    margin-top: 8%;
    font-family: Poppins;
    // background-color: #303030;
    .Dollarsymbol,h4{
      position: absolute;
      color: #000;
      margin-left: 80px;
      margin-top: 7px;
      font-family: Poppins, sans-serif;
    }
  }
 
  @media screen and (min-width: 320px) and (max-width: 900px) {
    margin-top: -2% !important;
    font-size: 1.3rem !important;
  }
}

.pricing-card-plan-dsc {
  text-align: center;
  font-family: sans-serif;
  
}
 //here
.pricing-card-cost {
  text-align: center;
  padding-top: 10px;
  font-family: "Roboto", Sans-serif;
  margin-top: -10%;
  font-style: normal;
  font-weight: 600;
  font-size: 55px;
  line-height: normal;
  color: #000;

 
}
.pricing-card-cost-price{
  font-size: 1.55rem;
  font-weight: 600;
  
  margin-top: -8%;
}
.pricing-card-cost-freq {
  font-weight: 400;
  font-size: 0.9rem;
  margin-top: 1.5%;
}

.sub-details-cost-plan{
font-family: Poppins;
}

.sub-details-plan{
  color:#000 ;
  font-family: Poppins;
  font-size: 15px;
  // background-color: #303030;
  // color:#29C2B6;
  margin-bottom: -15px;
}

@media screen and (max-width: 600px) {
  .index {
    width: 100%;
    padding: 1%;
    
  }

  .pricing-card-cost {
    font-size: 1.45rem;
  }
  .pricing-card-cost-freq {
    margin-top: 3%;
  }
}

@media screen and (min-width: 320px) and (max-width: 500px) {
  .index {
   // font-size: 1.275rem !important;
   font-size: 14px;
  }
  .parent{
    margin-left: -14%;
    margin-top: 10px;
    
  }
}

@media screen and (min-width: 500px)  {
  
  .parent {
    // max-height: 596px;
    margin-left: 1.75em;
    margin-top: 10px;
  }
}

@media screen and (min-width: 600px) and (max-width: 900px) {
  .index {
    font-size: 1.275rem !important;
  }
  .parent {
    // max-height: 596px;
    margin-left: 5em;
    margin-top: 10px;
  }
  .companyName {
    font-size: 1.438rem;
  }
  .pricing-card-cost {
    font-size: 1.563rem;
  }
  .cardStatus {
    width: 100px !important;
  }
}

@media (min-width: 1300px) {
}
@media (min-width: 1400px) {
}
@media (min-width: 1520px) {
}

@media (min-width: 1920px) {
  .index {
    font-size: 22px;
    padding: 2% 0px;
   // background-color: #303030;
  }
  .parent {
    // max-height: 425px;
    min-width:350px;
    height:420px;
   
    // background-color: #303030;
    width:600px;
  }
  .subscribe-button-price-categories{
    width:290px;
    &__button-top-margin-saas{
      margin-top: 35px;
    }
    &__button-top-margin-mgd{
      margin-top: 32px;
      width:290px;
      padding-top: -20px;
      background-color: #41E799;
      .ant-btn{
        height: 50px;
      }
    }
    &__button-top-margin-self{
      margin-top: 35px;
      width:290px;
      .ant-btn{
        padding-top: -5px;
        height: 50px;
      }
    }
  }

  .title-pricing-card{
    width:77%;
    }
 
}

@media (min-width: 2560px) {
  .index {
    font-size: 22px;
    padding: 2% 0px;
   // background-color: #303030;
  }
  .parent {
    // height: 325px;
    margin-left: auto;
    margin-right: auto;
    // background-color: #303030;
    width:500px;
    padding: 10px;
  }
  
  .title-pricing-card{
  
    width:77.5%;
    background-color: #1C1850;
    margin-top: -0.99em;
    margin-left:-0.8em ;
    height:4.5em;
    color:#fff;
    }
}

