@import "../../style/variables/colors";

.dialog {

  &-root {

    .ant-modal-content, .ant-modal-header{
      border-radius: 5px;
    }

    .ant-modal-body {
      padding: 0;
      display: flex;
      flex-direction: column;
      flex: 1;
    }

    .icon-container {
      float: right;
      cursor: pointer;
    }

    .section {

      &-top {
        padding: 5px;
        padding-top: 5px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        height:100px;
      }
  
      &-bottom {
        padding: 24px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
  
    }

    .success {
      background: #49B93A
      ;
    }

    .error {
      background: #FB564E;
    }

    .default {
      background:$white;
    }

    .header {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 10px 8px;
      margin-left: 14px;

      .title {
        font-family: "Poppins";
        font-size: 22px;
        font-weight: 600;
        margin-top: -2em;
        text-align: center;
      }

      .default-dialog-icon {
        font-size: 30px;
      }
    }

    .content {
      font-family: "Poppins";
      font-size: 15px;
      width: 22em;
      text-align: center;
    }

    .action {
      margin-top: 1em;
      font-size: 16px;
      color: #49B93A;
      width: 15em;
      border:none;
      border-radius: 3px;
      background-color: transparent;
      border: #49B93A 1px solid;
      &:hover{
        background-color: #1C1850;
      }
      // background-image:linear-gradient(to right, #29C2B6, #2CD97B )
    }

    .btn-error {
      color: $secondaryColor;
      border: $secondaryColor 1px solid;
      &:hover{
        background-color: #1C1850;
      }
    }

    .btn-error :hover {
      outline: $secondaryColor;
    }
  }
}