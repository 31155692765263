@import "../../../../components/style/variables/colors" ;

.main-container-header-details{
    flex-direction: row;
    padding: 5px;
    display: flex;
   
}

.ant-btn{
    &:hover{
        background-color: #1C1850;
        color:#FFF
    }
}

.modal-create-cluster{
    font-size: 12px;
    .ant-modal-content{
width:680px;
justify-content:center;
text-align: left;
// background-color: #006675;

    }
    .ant-modal-title {
        font-weight: 750;
        font-size: 18px;
        font-family: Poppins;
    }
    .ant-input{
        width:340px;
        margin-left: 20px;
    }
}
.form-storage{
   .input-component{
    margin-left: 1em;
   }
    // background-color: #006675;
}
.cancel-button-create-cluster{
    justify-content: space-between;

    .ant-btn-primary{
        // border: #41E799 solid 0.75px;
        background-color: #FFF;
        // background-image:linear-gradient(to right, #29C2B6, #2CD97B );
    // margin-top: -20px;
    color:#3A3A3A;
    position: absolute;
    margin-left: 25%;
    margin-top: -30px;
    border-style:solid ;
    border-width: 2px;
    border-color: #DD0C4E ;

}
:hover{
    background-color: #1C1850;
    color:#FFF;
}
}

.cluster-delete-button{
    color:#3A3A3A ;
    .delete-outlined:hover{
        cursor: pointer;
        color: red;
    }
    span{
        font-size: larger;
        color: #3A3A3A ;
    }
}
.back-button-create-cluster{

   
    .ant-btn-primary{
        // border: #41E799 solid 0.75px;
        background-color: #FFF;
        // background-image:linear-gradient(to right, #29C2B6, #2CD97B );
    // margin-top: -20px;
    color:#3A3A3A;
    position: absolute;
    margin-left: -22%;
    margin-top: -30px;
    width:80px;
    border-style:solid ;
    border-width: 2px;
    border-color: #DD0C4E ;
//    border-image:linear-gradient(to top right, #006675 5% ,#2FA46E 50%,#30A56E 40%);
//    border-image-slice: 1;
//    -webkit-mask: /*4*/
//    linear-gradient(#fff 0 0) padding-box, 
//    linear-gradient(#fff 0 0);
}

:hover{
    background-color: #1C1850;
    color:#FFF;
}

}
.submit-button-create-cluster{

    .ant-btn-primary{
    // background-color: #41E799;
    // background-image:linear-gradient(to right, #29C2B6, #2CD97B );
    // margin-top: -20px;
    background-color: #DD0C4E ;
    color:#FFF;
    position: absolute;
    border: none;
    margin-left: 1%;
    margin-top: -30px;
}
:hover{
    background-color: #1C1850;
    color:#FFF;
}
}
.div-dashboard-all-clusters{
    width: 80vw;
    margin-left: 10px;
    margin-top: 2em;
    // position: relative;
    min-height: 100vh;
    // height:100vh;
    // position: absolute;
    // margin-left: auto;
    // margin-right: auto;
    // background-color: #3d36f9;
    .Button:hover{
        background-color: #1C1850;
    }
    .ant-btn:focus, .ant-btn:active {
        // background-image:linear-gradient(to right, #29C2B6, #2CD97B );
        background-color: #DD0C4E;
        text-decoration: none;
        color:#FFF;
    }
    .searchWrapper{
border-color: #1C1850;
    }
}
.loading-cluster-creation{
    margin-left: 45%;
    position:absolute;
    font-size: 30px;
    margin-right: auto;
    margin-top: -50%;
    width: 100px;
    height:100px;
}
.reset-to-config{
    margin-left: 50%;
    position: absolute;
}
.footer-dashboard-nocluster{
    position: absolute;
    margin-top: 70vh;
    margin-left: 30%;
    // margin-right: auto;

}

.cluster-list-table{
    
    width: 90vw;
    // align-self: center;
    //  position: relative;
    margin-left:80px ;
    margin-top: 3em;
    // background-color: #1C1850;
    margin-right:5px ;
    span{
        font-size: 15px;
    }
    
    Table{
    width: 98%;
    margin-top: 0px;
    
    }
    .ant-table-pagination.ant-pagination{
        margin-left: 950px;
        width: max-content;
        }

.ant-table-column-sorter {
    margin-left: 4px;
    color: #FFF ;
    font-size: 0;
    transition: color 0.3s;
    // visibility: hidden;
}
// .ant-table-column-sorter:after {
   
//     color:#41E799 ;
    
// }


.ant-table{
    width:90vw;
   
}

    .ant-table-thead >tr>th{
        min-width: 65px;
        //  background-color:#2CD97B;
        // background-color: #41E799;
        // background: linear-gradient(180deg, #DD0C4E 20%, #1C1850 100%);
        background-color: #1C1850;
        color: #FFF;
        padding-left: 2px;
        padding-right: 2px;
        margin-top: -5px;
    }
    .ant-table-tbody >tr>td{
        min-width: 60px;
        text-transform:none;
        // background-color: #41E799;
       
    }
    .server-id-button{
background-color: transparent;
    }
    .ant-tag{
        border: none;
        background-color: transparent;
        color:#DD0C4E;
        font-size: 17px;
        
    }
    td.ant-table-column-sort {
        background: transparent;
    }
}


.add-cluster-button-home-top-right{
   
    border: none;
    border-radius: 3px;
    font-family: Poppins;
    margin-left: 78.25em;
    position:absolute;
     margin-top: -2px;
     width: fit-content;
     height:32px;
     padding: 5px;
    Button{
        // background-image:linear-gradient(to right, #29C2B6, #2CD97B );
        background-color: #DD0C4E;
        border: none;
        border-radius: 3px;
        color: #FFF;
        font-family: Poppins;
    }
    Button:hover{
        background-color: #1C1850;
        color: #FFF;
    } 
    }
    .add-cluster-button-home{
        // Button{
        //     color: #FFF;;
        //     // background-color: #41E799;
        //     background-image:linear-gradient(to right, #29C2B6, #2CD97B );
        //     border: none;
        //     padding: 5px;
        //     font-family: Poppins;
        //     width: max-content;
        //     border-radius: 3px;
        // }
        // Button:hover{
        //     background-color: #1890ff;
        //     color: #FFF;
        //     border-radius: 3px;
        // }
        margin-left: 30px;
        
        width: fit-content;
        margin-bottom: 20px;
       
     
    }
.no-subscription-messagebox{
    min-width: 350px;
    width: fit-content;
    // position:absolute;
    border:0px solid #3A3A3A ;
    margin-left: 250px;
    margin-top: 150px;
    // margin-left: 5vw;
    // margin-top: -350px;
    font-family: Poppins;
    
    padding: 10px;
    padding-top: 10px;
    color:#DD0C4E;
    border-radius: 8px;
    // border: transparent  6px solid;
    border-width: 4px;
    border-style: solid;
    border-color: #1C1850 ;
//     border-image:linear-gradient(to top right, #006675 5% ,#2FA46E 50%,#30A56E 40%);
//     border-image-slice: 1;
//     -webkit-mask: /*4*/
//   linear-gradient(#fff 0 0) padding-box, 
//   linear-gradient(#fff 0 0);
    // background-image:linear-gradient(to right, #006675 ,#30A56E);

    .ant-card{
       
        //  background-color: #006675;
         background: transparent;
        // background-image:linear-gradient(to right, #006675 ,#30A56E);
         border: #FFF 0px solid;
        h5,h3,p{
            // color: #000;
            color:inherit;
            margin-left: 10px;
            // margin-right: auto;
        }

    }
    .ant-card-head{
        border: none;
    }
    .ant-card-head-title{
        font-size: 23px;
      // padding-left: 30%;
      color: #DD0C4E;
    //    color: #000;
       text-align: center;
       border: none;
    }
    .ant-card-body{
        // background-color: #006675;
        height:150px;
        // color:#41E799;
    }
    Button{
        background-color: #DD0C4E ;
        // background-image:linear-gradient(to right, #29C2B6, #2CD97B ); 
        align-self: center;
        padding-left: 13px;
        // margin-left: auto;
        // margin-right: auto;
         margin-left: 50px;
         border-radius: 3px;
        border: none;
        text-align: center;
        color: #FFF;
    }
    Button:hover{
        border: #DD0C4E 1px solid;
        background-color: #1C1850;
        color: #FFF;
    }
}
.clusters-spike-dashboard{
  position: absolute;
  margin-top: -30px;
//   background-color: #3FCD7F;
}

.card-holder-0{
    width:200px;
    text-align: center;
    border: 1px black solid;
    margin-bottom: 30px;
    // margin-left: 20%;
    .ant-card-head-title{
        font-size: larger;
        font-family: Poppins;
    }
    .ant-card-head {
        border:none;
    }
    p{
        margin-top: -8px;
    }
    .card-header-p{
        font-size: 20px;
        color: #3FCD7F;
        font-family: Poppins;
    }
    .icon-dashboard{
        width: 40px;
        height: 40px;
        position: relative;
        border: none;
        margin-top: -20px;
        margin-bottom: 5px;
    }
    
}
.card-holder-1{
    position: absolute;
    width:200px;
    border: 1px black solid;
    text-align: center;
     margin-left: 30%; 
    .icon-dashboard{
        width: 40px;
        height: 40px;
        position: relative;
        border: none;
        margin-top: -20px;
        margin-bottom: 5px;
    }
    p{
        margin-top: -8px;
    }
    .ant-card-head {
        border:none;
    }
    .ant-card-head-title{
        font-size: larger;
        font-family: Poppins;
    }
    .card-header-p{
        font-size: 20px;
        color: blue;
        font-family: Poppins;
    }
   
}
.card-holder-2{
    width:200px;
     //height: 180px;
     position: absolute;
    //  background-color: #33ec8f;
    border: 1px black solid;
    text-align: center;
    margin-left: 60%; 
    p{
        margin-top: -8px;
    }
    .ant-card-head-title{
        font-size: larger;
        font-family: Poppins;
    }
    .card-header-p{
        font-size: 20px;
      
        color: #ECA335 ;
        font-family: Poppins;
    }
    .icon-dashboard{
        margin-bottom: 5px;
        width: 40px;
        height: 40px;
        position: relative;
        border: none;
        margin-top: -20px;
    }
   // margin-top: -13.5%;
}
.card-header-server-details{
//   background-color: rgb(204, 134, 42);  
  height: 140px;
  
}

.config-details-modal-values{
    font-family: Poppins;
    // width: 1200px;
    //  margin-left: 15em;
  
    // background-color: #006675;
    h5{
        font-family: Poppins;
        margin-top: 1.5em;
        margin-bottom: 0.5em;

        font-weight: bolder;
    }
    .ant-modal{
        background-color: #1890ff;
        
    }
    .ant-modal-header{
        background-color: #1C1850;
        .ant-modal-title{
            color:#FFF;
        }
       
      
       
    }
    .ant-modal-content{
        .ant-modal-close{
            color:#FFF;
        }
        margin-left: auto;
        margin-right: auto;
        width: 600px;
        margin-top: -3em;
        
        .indexer-table{
            width: 550px;
            td{
                padding-left: -30px;
                width:260px;
            }
        }
    //    table{
    //     width: 525px;
    //    }
        
        td{
            padding-left: 10px;
            border: none;
            font-size: 13px;
            //  word-wrap: break-word;
            // overflow-wrap: break-word;
           
        }
        tr{
            // border-bottom: #000 1px solid;
            word-wrap: break-word;
            overflow-wrap:inherit;
            border-bottom: #000 0.5px solid;
            padding-top: 1px;
        }
    }
    .ant-typography{
        font-size: medium;
        text-transform: capitalize;
        justify-content: space-around;
        // background-color: #3FCD7F;
    }
    span{
    margin-left: 10px;
    }
}


.server-config-button{

    width:fit-content;
    text-align: center;
    font-weight: 600;
    font-family: Poppins;
    color: #3d36f9;
    padding: 5px;
    background-color: yellow;
     font-size: 15px;
    cursor: pointer;
    border: none;
    text-decoration: underline;
    span:hover{
    //   color:#4ad39e ;
      background-color: transparent;
      font-weight: 700;
    }
  }

.p-dashboard-card{
font-family: Poppins;
font-size: medium;
padding-bottom: -5px;
}

.dropdown-select-env-type{
    width:400px;
    margin-bottom: 40px;
    margin-top: -30px;
    border-color: #DD0C4E;
   
    .filter-icon{
     position:absolute;
     margin-left: 380px;
     margin-top: 10px;
     color: #DD0C4E;
   }
    
}
.selected-env-types{
    position:absolute;
    margin-top: 47px;
    margin-bottom: 20px;
    min-width: 150px;
    border-radius: 10px;
    .text-chip{
        width:150px;  
        border-radius: 7px;
    }
    
    span{
        // min-width: 100px;
        // background-color: #41E799;
        margin-top: 100px;
        background-color: #1C1850 ;
        // background-image:linear-gradient(to right, #006675 ,#30A56E);
        color:#FFF ;
        width:200px;
        padding: 5px;
        padding-left: 20px;
        padding-right: 20px;
        margin-left: 5px;
        // margin-bottom: 20px;
        margin-top: 10px;
    }  
}

.apply-filter-button-dashboard{
    margin-left: 34%;
    position:absolute;
    border-radius: 3px;
    // background-image:linear-gradient(to right, #29C2B6, #2CD97B );
//    border:1px #41E799 solid;
    border-style:solid ;
    border-width: 2px;
    border-color: #DD0C4E ;
    &:hover{
        background-color: #1C1850;
        color:#FFF;
    }

    Button{
       
        height:32px;
        color:#000;
        border-radius: 3px;
        border: none;
        padding:5px 10px 5px 10px;
        font-family: 'Poppins';
        :hover{
            background-color: #1C1850;
            color:#FFF
        }
    }


// Button:hover{
//     // background-color: #1890ff;
//     color:#1C1850
// }
}
.clear-filter-button-dashboard{
    border-radius: 3px;
    margin-left: 43%;
    position:absolute;
    // background-image:linear-gradient(to right, #29C2B6, #2CD97B );
    // border:1px #41E799 solid;
    border-style:solid ;
    border-width: 2px;
    border-color: #DD0C4E ;

&:hover{
    background-color: #1C1850;
    color:#FFF;
}
    Button{
    height:32px;
    border-radius: 3px;
    // background-image:linear-gradient(to right, #29C2B6, #2CD97B );
    color:#000;
    padding-left: 10px;
    padding-right: 10px;
    // background-color: #41E799;
    border: none;
    font-family: 'Poppins';
    :hover{
        background-color: #1C1850;
        color:#FFF;
    }
}
    // button:hover{
    //     // background-color: #1890ff;
    //     // color:#FFF;
    //     color:#FFF;
    // }

}
.search-button-dashboard{
    margin-left: 51%;
    height:32px;
    position:absolute;
    color: #FFF;
    background-color: #DD0C4E ;
    // background-image:linear-gradient(to right, #29C2B6, #2CD97B );
    // background-color: #41E799;
    border: none;
    font-family: 'Poppins';
}

.logs-url{
    // width:430px;
    // width:100%;
width:30.5em;
    height:80px;
    border:3px #1C1850 solid;
    // background-color: #3A3A3A ;
    color:#3A3A3A ;
    font-family: Poppins;
    padding: 15px;
    // padding-bottom: 10px;
    // margin-bottom: 30px;
    .url-link{
        text-decoration: underline;
        font-size: 15px;
        margin-bottom: 20px;
       color:#DD0C4E;
        .copy-outlined-button{
             color: #3A3A3A ;
             margin-left: 10px;
    }
    }
    .doc-link{
        text-decoration: none;
    }
}

.footer-dashboard-cluster-list{
    margin-top: 15%;
}

@media (min-width:1300px){
    .cluster-list-table{
    //  margin-left: 4.5em;
    margin-left: 11.5em;
     width:85%;
     .ant-table{
        width: 77vw;
     }
    .ant-table-pagination.ant-pagination{
    margin-left: 65em;
    width: max-content;
    }
    }
    .add-cluster-button-home-top-right{
         margin-left: 77em;
    }
} 

@media (min-width:1440px){
  
    .cluster-list-table{
   
        margin-left: 13em;
        width:90%;
        Table{
            width: 87vw;
        }
    .ant-table-pagination.ant-pagination{
    margin-left: 70em;
    width: max-content;
    }
    }
    .add-cluster-button-home{
        // margin-left: 10%;
    }
    .apply-filter-button-dashboard{
        margin-left: 30%;
        }
        
        .clear-filter-button-dashboard{
            margin-left: 39%;
            padding-left: 10px;
            padding-right: 10px;
        }
    .add-cluster-button-home-top-right{
        margin-left: 82em;
    }
}

@media (min-width:1530px){
    .cluster-list-table{
        margin-left: 14em;
        .ant-table{
            width: 1175px;
        }
    .ant-table-pagination.ant-pagination{
    margin-left: 70em;
    width: max-content;
    }
    }
    .add-cluster-button-home{
        // margin-left: 9%;
    }
    .add-cluster-button-home-top-right{
         margin-left: 77.5rem;
         margin-top: -4px;
    }
}
@media (min-width:1630px){
    .cluster-list-table{
        margin-left: 13em;
        .ant-table{
            width: 1350px;
        }
        .ant-table-pagination.ant-pagination{
            margin-left: 85em;
          
            }
    }
    .add-cluster-button-home-top-right{
        margin-left: 87.5rem;
        
   }
}
@media (min-width:1920px){
    .cluster-list-table{
    // margin-top: 50px;
    width: 85%;
    margin-left: 100px;

    .ant-table{
        width:1650px;
    }
    
    .ant-table-thead >tr>th{
        min-width: 130px;
    }
    .ant-table-tbody >tr>td{
        min-width: 130px;
    }
    .ant-table-pagination.ant-pagination{
    margin-left: 1450px;
    width: max-content;
    }
    }
    .add-cluster-button-home-top-right{
        margin-left: 84.25%;
    }

    .add-cluster-button-home{
        // margin-left: 9%;
    }
    .no-subscription-messagebox{
        // margin-top: -600px;
        
    }
    .apply-filter-button-dashboard{
        margin-left: 24%;
        }
        
        .clear-filter-button-dashboard{
            margin-left: 30%;
        }
}

@media (min-width:2560px){
    .cluster-list-table{
    
    margin-left: 32em;
   
    .ant-table-pagination.ant-pagination{
    margin-left: 1500px;
    width: max-content;
    }
    }
    .add-cluster-button-home-top-right{
        margin-left: 76.5%;
    }
    .add-cluster-button-home{
        // margin-left: 7.75%;
    }
}

.no-logs-card{
    // style={{marginTop:5,border:'none', width:250,height:150,backgroundImage:"linear-gradient(to right, #006675 ,#30A56E)",  color:"white"}}
     margin-top: 20px;
    display: flex;
    align-items:flex-start;
    justify-content: space-around;
    // background-color: #006675;
    width:91.75vw;
    height: 125px;
    color: #000;
    border-radius: 4px;
    background-color: transparent;
    font-family: Poppins;
    border-radius: 8px;
    margin-left: -1em;
    margin-top: 0.5em;
    @media (min-width:1630px)
    {
        // margin-left: 40rem;
        width:92vw;
    }
   

    .ant-card{
        width:290px;
        color:#A9A9A9;
        background-color: transparent;
        border: #FFF 0px solid;
        border-radius: 8px;
        // border: transparent  6px solid;
        border-width: 4px;
        border-style: solid;
        border-color: #DD0C4E ;
        // border-image:linear-gradient(to top right, #006675 5% ,#2FA46E 50%,#30A56E 40%);
        // border-image-slice: 1;
        // -webkit-mask: /*4*/
        // linear-gradient(#fff 0 0) padding-box, 
        // linear-gradient(#fff 0 0);
        h5,h3,p{
            color:#A9A9A9;
            margin-left: 10px;
        }
    }
    .ant-card-head{
        border: none;
        height:2px;
        color:#A9A9A9;
    }
    .ant-card-body{
        margin-top: -25px;
    }
}

.footer-no-subscription{
   margin-top: 40%; 
}


@media (min-width:320px) and (max-width:900px) {
.div-dashboard-all-clusters{
    margin-left: -1.2em;
}
    .cluster-list-table{
    width: 75vw;
    // background-color: #016775;
    .ant-table{
        width: 75vw;
    }
    
    }
    .footer-dashboard-cluster-list{
        margin-top: 15%;
        margin-left: 10%;
    }
    .logs-url{
        // width:430px;
        // width:100%;
    width:20.5em;
    height: max-content;
    padding-bottom: 7px;
    }
    .apply-filter-button-dashboard{
        // margin-top: 10%;
        display: none;
        opacity: 0;
        pointer-events: none;
        visibility: none;
        z-index: -1;
        // background-color: #006675;
        }
        
        .clear-filter-button-dashboard{
            // margin-left: 30%;
            visibility: hidden;
        }
        .searchWrapper{
            visibility: hidden;
        }
        .multiSelectContainer{
            visibility: hidden;
        }

        .dropdown-select-env{
            visibility: hidden;
        }
        .add-cluster-button-home-top-right{
            display: none;
            opacity: 0;
            pointer-events: none;
            visibility: none;
            z-index: -1;
        }
        .no-subscription-messagebox{
        margin-left: 6.25em;
        padding-left: 5px;
        width: 225px;
        }
        .no-logs-card{
            margin-left: 3em;
            margin-top: 5em;
        }
        .add-cluster-button-home{visibility: hidden;// margin-left: 7.75%;
        }
   }