$small: 5px;
$medium: 10px;
$large: 15px;

$padding-small: $small;
$padding-medium: $medium;
$padding-large: $large;


$margin-small: $small;
$margin-medium: $medium;
$margin-large: $large;
