.box-navigation
{
    margin-top: -3.4em;
    margin-left: 5em;
   
}
.cluster-drop-down-select{
    svg{
        color: #DD0C4E;
    }
}
.bg-img-search{
    position: absolute;
    opacity: 0.4;
    height: 95vh;
    margin-left: 5em;
    width:93.5vw;
    @media  (min-width:1680px) {
        width: 96.5vw;  
    }
}
.bg-dark-search{
    position: absolute;
    opacity: 0.85;
    height: 105vh;
    margin-left: 4em;
    // width:85.5em;
    margin-top: -6.5em;
     width:94vw;
     @media  (min-width:1360px) {
        width:95.5vw;
    }
     @media  (min-width:1680px) {
        width: 96.5vw;  
    }
    @media  (min-width:2560px) {
        width: 97vw;  
    }
   
}
.box-form{
    margin-left: -1rem;
    background-color: #f0f0f0;
    width: 92.3vw;
    padding-top: 0.5em;
    border: #1C1850 1px solid;
  
    @media  (min-width:1360px) {
        margin-left: 0;   
    }
    @media  (min-width:1680px) {
        width: 93vw;  
    }
    @media  (min-width:2560px) {
        width: 95vw;  
    }
}

.box-form-dark{
    margin-left: -1rem;
     background-color: #555353;
     padding-top: 0.5em;
     border: #1C1850 2px solid;
     margin-top: -5.5em;
    width: 92.3vw;
    z-index: 150;
    position: relative;
    // border:#CDCDCD 1px solid;
    padding-top: 5px;
    @media  (min-width:1360px) {
        margin-left: 0; 
          
    }
    @media  (min-width:1680px) {
        width: 93vw;  
    }
    @media  (min-width:2560px) {
        width: 94.5vw;  
    }
}


.search-query-field{
    background-color: #FFF;
    color: #6BB660;

}


.button-outline{
    border: #1C1850 1px solid;
    background-color: #FFF;
    color: #000;
    font-family: Poppins;
}


.table-row-logs-red{
  
    border-top: 3px transparent solid;
border-left: 2px solid red;
p { 
    padding-left: 5px;
    padding-top: 1px;
}
}
.table-row-logs-green{
    
    border-top: 3px transparent solid;
    // background-color: antiquewhite;
    border-left: 2px solid #6BB660;
    p { 
        padding-left: 5px;
    }
}

.table-row-logs-yellow{
    border-top: 3px transparent solid;
    border-left: 2px solid #F1F41A;
    p { 
        padding-left: 5px;
   }
}

// .theme-button{
//         position: absolute;
//         margin-left: 140vw;
//         margin-top:2.2em;
//         z-index: 25;
//         border: 1px #1C1850 solid;
//         padding:5px;
//         border-radius: 4px;
//         @media  (min-width:1300px) {
//             margin-left: 146vw;
           
//         }
//         @media  (min-width:1440px) {
//             margin-left: 146vw;
           
//         }
//         @media  (min-width:1535px) {
//             margin-left: 150vw;
            
//         }
//         @media  (min-width:1680px) {
//             margin-left: 155vw;
           
//         }
//         @media  (min-width:1920px) {
//             margin-left: 158vw;
           
//         }
//         @media  (min-width:2560px) {
//             margin-left: 168vw;
           
//         }
        
// }
.grid-save-filter{
    display: flex;
    width:520px;
    // background-color: #41E799;
   position: relative;
margin-left: -115%;
margin-right: 70.75%;
// margin-left: -85rem;
// margin-right: 52rem;
margin-bottom: 10px;

@media   (max-width:900px) {
    margin-left: -115%;
    margin-right: 48.75%;
    // margin-left: -90rem;
    // margin-right: 57rem;
}

@media  (min-width:900px) and (max-width:1240px) {
    margin-left: -115%;
    margin-right: 60.75%;
    // margin-left: -90rem;
    // margin-right: 57rem;
}
@media  (min-width:1300px) {
    margin-left: -115%;
margin-right: 72.5%;
    // margin-left: -90rem;
    // margin-right: 57rem;
}
@media  (min-width:1440px) {
    margin-left: -94.5rem;
    margin-right: 61.25rem;
}
@media  (min-width:1535px) {
    margin-left: -100rem;
    // margin-right: 66.75rem;
    margin-right: 75.6%;
}
@media  (min-width:1680px) {
    margin-left: -109rem;
    // margin-right: 75.875rem;
    margin-right: 77.8%;
}
@media  (min-width:1920px) {
    margin-left: -123rem;
    // margin-right: 89.75rem;
    margin-right: 80.6%;
}
@media  (min-width:2560px) {
    margin-left: -163rem;
    margin-right: 129.5rem;
}
}

.search-tail-grid{
    display: flex;
    height: fit-content;
     margin-top: -9.7em ;
     @media   (max-width:970px) {
        margin-top: -10.4em ;
    }
     @media  (min-width:970px) and (max-width:1240px) {
        margin-top: -13.4em ;
        // margin-left: -90rem;
        // margin-right: 57rem;
    }
    // margin-left: 750px, top -150px;
}

.box-tail-logs{
    margin-top: 5px;
    font-family: Poppins;
    margin-left: -1rem;
    width:92.5vw;
    @media  (min-width:1300px) {
        margin-left: 0rem;
    }
    @media  (min-width:1440px) {
        
    }
    @media  (min-width:1535px) {
      
    }
    @media  (min-width:1680px) {
        width:93vw;
    }
    @media  (min-width:1920px) {
     
    }
    @media  (min-width:2560px) {
        width: 94.35vw; 
    }
}

.logs-table{
    // background-color: #6BB660;
    margin-left: 5px;
    margin-right: -5px;

}

.responsive-container-graph{
    //  background-color: #6BB660;
   padding-left: -20px;
}
.bar-graph{
    margin-left: -30px;
}

.box-graph-logs{
    //  background-color: #FFF;
    margin-left: -1rem;
    width:92.5vw;
    
    max-height: 72%;
    // height:65vh;
    @media  (min-width:1300px) {
        margin-left: 0rem;
    }
    @media  (min-width:1440px) {
       
    }
    @media  (min-width:1535px) {
        
    }
    @media  (min-width:1680px) {
        padding-left: 2px;
        width:93vw;
        max-height: 78%;
    }
    @media  (min-width:1920px) {
        
    }
    @media  (min-width:2560px) {
        width: 94.35vw;  
        height: 130em;
    }
}

.grid-query-repo{
     margin-left: 11.5rem;
     margin-right: 7px;
}

#demo-simple-select-label{
    // background-color: #6BB660;
    margin-top: -5px;
    // justify-content: center;
}


.date-time-grid{
    display: flex;
    // background-color: #F1F41A;
    height: fit-content;
    width: 375px;
    margin-top: -120px;
    margin-left: 30vw;
    
}

.clear-all-button{
    margin-left: 0.5rem;
    display: inline;
    margin-top: 8px;
    Button{
        height:40px;
    }
}


.latency-time-grid{
    margin-left: 0.25rem;
    // background-color: #FFF;
    margin-top: 8px;
    padding: 6px 5px;
    border-radius: 3px;
    // border: #41E799 1px solid;
    height: 40px;
    width:90px;
    font-size: 11px;
     text-align: center;
     border-style:solid ;
     border-width: 1.5px;
    //  border-color: #1C1850;
    border-width: 1.5px;
    border-style: solid;
    border-image:linear-gradient(to bottom right,#DD0C4E 10%,#2b2672 80%);
    border-image-slice: 1;
    -webkit-mask: /*4*/
    linear-gradient(#fff 0 0) padding-box,
    linear-gradient(#fff 0 0);
    // border-image:linear-gradient(to top right, #006675 5% ,#2FA46E 50%,#30A56E 40%);
    // border-image-slice: 1;
    // -webkit-mask: /*4*/
    // linear-gradient(#fff 0 0) padding-box, 
    // linear-gradient(#fff 0 0);
    @media  (min-width:1300px) {
       
    }
    @media  (min-width:1440px) {
      margin-left: 1rem;  
      margin-right: 0.5rem;
    }
    @media  (min-width:1535px) {
        // margin-left: 6rem;   
        // margin-right: 1rem;
    }
    @media  (min-width:1680px) {
        margin-left: 1.75rem;  
      margin-right: 1rem;
    }
    @media  (min-width:1920px) {
        margin-left: 2rem;   
        margin-right: 2rem;
    }
    @media  (min-width:2560px) {
        margin-left: 4rem;   
        margin-right: 3rem;
    }
}

.group-div-select{
    display: flex;
    // flex-direction: column;
    
    flex-direction: row;
    height: 20em;
}

.group-relative{
    // position: ;
// background-color: #41E799;
scroll-behavior: smooth;
margin-left: 17em;

}

.group-absolute{
    position: fixed;
// background-color: #F1F41A;
width: 17em;
// font-size: 14px;

label{
    margin-top: 0.75rem;
}

}
.date-picker{
    // background-color: #1C1850;
}

.form-select-duration{
    svg{
        color: #DD0C4E;
    }
    width: 10rem;
 

    #select-label{
        padding-top: 15%;
        // color: #6BB660;
        font-size: 16.5px;
        text-align: justify;
        font-family: Poppins;
        // margin-top: -0.4rem;
        justify-self: center;
        justify-content: center;
        // color: yellow;
    }
}

.form-select-duration-abs{
    width: 16rem;
 

    #select-label{
        padding-top: 10%;
        // color: #6BB660;
        font-size: 16.5px;
        text-align: justify;
        font-family: Poppins;
        // margin-top: -0.4rem;
        justify-self: center;
        justify-content: center;
        // color: yellow;
    }
}


.alert-dashboard{
    margin-left: 5em;
    margin-top: 2em;

    button{
        color:#000
    }
}



.button-alert-config{
    margin-left:25em;
    width: fit-content;
    height:40px;
    color: #000;
    // border: #41E799 1px solid;
    margin-top: -39.5px;
    text-transform: none;
    padding-top: 0.25rem;
   
    border: #1C1850 2px solid;
//    border-image:linear-gradient(to top right, #006675 5% ,#2FA46E 50%,#30A56E 40%);
//    border-image-slice: 1;
//    -webkit-mask: /*4*/
//    linear-gradient(#fff 0 0) padding-box, 
//    linear-gradient(#fff 0 0);
}

.button-alert-config-logs{
    margin-left:25em;
    // margin-right:-2em;
    border-radius: 5px;
    width: fit-content;
    height:40px;
    color: #000;
     border: #1C1850 1px solid;
    margin-top: -40px;
    text-transform: none;
    

    // padding-top: 0.25rem;
}


.add-rule-button{
   color:#000;
   width: 160px;
//    margin-left: 77vw;
margin-left: 71.5em;
@media  (min-width:1300px) { 
    margin-left: 80vw;
}
@media  (min-width:1440px) {
    
}
@media  (min-width:1535px) {
    
}
@media  (min-width:1680px) {
    margin-left: 83vw;
}
@media  (min-width:1920px) {
    margin-left: 85vw;
}
@media  (min-width:2560px) {
    margin-left: 89vw;
}
Button{
    width: 140px;
    border: #1C1850 1px solid;
    margin-top: -8em;
}
}


.form-rules{
    label{
        color:'#DCDCDC'
    }
}