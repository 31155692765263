@import "../../../components/style/variables/colors";



.gray-text {
  color: $veryDarkGray;
  font-family: "Poppins";
  font-size: 12px;
  line-height: 18px;
}

.license-plan{
  position: relative;
  margin-left:20px;
  width:275px;
  color: #000;
}

.line-break {
  // border-right: 2px solid rgba(0, 0, 0, 0.06);
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.profile-container-crm{
  display:flexbox;
  flex-direction: row;
  margin-top: -4em;
  color: #000;
  height:113vh;
  width:95vw;
  margin-left: 4em;
  @media (min-width:1400px) {
    height:100vh;
  }

  .ant-btn{
    border: none;
    background-color: #DD0C4E;
    // background-image:linear-gradient(to right, #29C2B6, #2CD97B );
    color: #FFF;
    border-radius: 3px;

    :hover{
      background-color: #1C1850;
    }

    
    
  }

  .ant-typography{
    color: #000;
  }
  padding: 25px;  
  width:max-content;
}

.div-container-layout{
  display: flex;
  flex-direction: row;
  align-items:flex-start;
  color: #000;
 margin-left: 140px;
  width: max-content;
  margin-top: 8em;
  
  @media (min-width:300px) and (max-width:500px) {
    flex-direction: column;
    margin-left: 55px;
    margin-top: -5px;
  }
  @media (min-width:500px) and (max-width:700px) {
    flex-direction: column;
    margin-left: 150px;
    margin-top: -75px;
  }
  @media (min-width:700px) and (max-width:900px) {
    flex-direction: column;
    margin-left: 200px;
    margin-top: -75px;
  }
  @media (min-width:1600px){
    margin-top: 10em;
  }
 
  
}
.parentContainer{
  width: 350px;
}
.org-container{
  width: 320px;  
   color: #000;
  //  border: transparent  6px solid;
  //  border-width: 3px;
  //  border-style: solid;
  //  border-radius: 4px;
  //  border-color: #1C1850;
  //  border-image:linear-gradient(to top right, #006675 5% ,#2FA46E 50%,#30A56E 40%);
  //  border-image-slice: 1;
  
  height:580px;
  padding:10px 5px;
  border-radius: 4px;
  border-width: 2px;
  border-style: solid;
  border-image:linear-gradient(to bottom right,#DD0C4E 10%,#2b2672 80%);
  border-image-slice: 1;
  -webkit-mask: /*4*/
    linear-gradient(#fff 0 0) padding-box,
    linear-gradient(#fff 0 0);
  // -webkit-mask: /*4*/
  // linear-gradient(#fff 0 0) , 
  // linear-gradient(#fff 0 0);
}


.profile-view {
  // padding: 1rem;
  display:flex;
  // border: transparent  6px solid;
  // border-width: 3px;
 
 
  align-items: flex-start;
  position: relative;
  // position: absolute;
  //  width: 330px;
   width:350px;
   
   margin-left: 20px;
  // margin-top: -45%;
  
  color: #000;
  // border-radius: 4px;
  // border-color: #1C1850;
  border-radius: 4px;
        border-width: 2px;
        border-style: solid;
        border-image:linear-gradient(to bottom right,#DD0C4E 10%,#2b2672 80%);
        border-image-slice: 1;
        -webkit-mask: /*4*/
        linear-gradient(#fff 0 0) padding-box,
        linear-gradient(#fff 0 0);

}
.profile-view-install-instructions{
  width: 50%;
  border-top: 2px solid rgba(0, 0, 0, 0.06);
  border-right: 2px solid rgba(0, 0, 0, 0.06);
  border-bottom: 2px solid rgba(0, 0, 0, 0.06);
  border-left: 0.5px solid rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  margin-left: 1rem;
  padding: 1rem;
  font-family: 'Poppins';
  .header{
    text-align: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    padding: 10px;
    .sub-header{
      margin-bottom: 5px;
      font-size: 1.5rem;
      font-weight: 600;
      font-family: 'Poppins';
    }
    .under-sub-header{
      font-size: 0.8rem;
    }
  }
  .profile-view-install-instructions-content{
    margin: 15px;
    .profile-view-install-instructions-content-header{
      font-size: 1.1rem;
      margin-top:20px;
    font-weight: 600;
    }
    .profile-view-install-instructions-content-body{
      margin-top: 20px;
      margin-left: 5%;
      font-size: 0.85rem;
      display: list-item;
      list-style-type: disc;
    }
  }
}

.headerProfile {
  display: flex;
  border-top: 2px solid rgba(0, 0, 0, 0.06);
  border-bottom: 2px solid rgba(0, 0, 0, 0.06);
  @media screen and (max-width: 600px) {
    border-right: 0px !important;
  }
}

.bottomheaderProfile {
  display: flex;
  border-right: 2px solid rgba(0, 0, 0, 0.06);
  border-bottom: 2px solid rgba(0, 0, 0, 0.06);
  border-left: 0.5px solid rgba(0, 0, 0, 0.06);
  @media screen and (max-width: 600px) {
    flex-wrap: wrap;
    flex-direction: row;
    // border-bottom: 2px solid rgba(0, 0, 0, 0.06);
    border-right: 0px !important;
    border-left: 0px !important;
  }
}

.subHeaderProfile {
  font-family: "Poppins";
  padding: 15px;
  color: #000;
  //  border-right: 2px solid rgba(0, 0, 0, 0.06);
  min-width: 20%;
  @media screen and (max-width: 600px) {
    min-width: 50%;
    border-right: 0px !important;
  }
}

.subPlanHeader {
  padding: 10px;
  border-right: 0px solid rgba(0, 0, 0, 0.06);
  width: 310px;
  @media screen and (max-width: 600px) {
    min-width: 50%;
    border-left: 2px solid rgba(0, 0, 0, 0.06);
    border-right: 0px !important;
    border-left: 0px !important;
  }
}

.subDateStartHeader {
  padding: 15px 10px;
  // border-right: 2px solid rgba(0, 0, 0, 0.06);
  min-width: 16%;
  @media screen and (max-width: 600px) {
    min-width: 50%;
    // border-bottom: 2px solid rgba(0, 0, 0, 0.06);
    border-left: 2px solid rgba(0, 0, 0, 0.06);
    border-left: 0px !important;
  }
}

.subDateEndHeader {
  padding: 15px 10px;
  // border-right: 2px solid rgba(0, 0, 0, 0.06);
  min-width: 16%;
  @media screen and (max-width: 600px) {
    min-width: 50%;
    // border-bottom: 2px solid rgba(0, 0, 0, 0.06);
    border-left: 0px !important;
    border-right: 0px !important;
  }
}

.subLicenseHeader {
  padding: 15px 10px;
  border-right: 2px solid rgba(0, 0, 0, 0.06);
  min-width: 33%;
  @media screen and (max-width: 600px) {
    min-width: 100%;
    border-top: 2px solid rgba(0, 0, 0, 0.06);
    border-bottom: 2px solid rgba(0, 0, 0, 0.06);
    border-right: 0px;
    border-left: 0px;
  }
}

.subLastHeader {
  padding: 10px 15px;
  background-color: $primaryColor;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 19%;
  @media screen and (max-width: 600px) {
    min-width: 100%;
  }
}

@media (max-width: 900px) {
}

.profile-dts-container {
  font-family: 'Poppins';
  width:340px;
  border-radius: 6px;
  // background-image:linear-gradient(to top right, #006675 5% ,#2FA46E 50%,#30A56E 40%);
  // color: #FFF;
  color: #000;
  // border-radius: 10px;
  padding: 25px;
  align-items: flex-start;

 
  Button:hover{
    
      background-color: #1C1850;
      color: #FFF;
    
  }
  

  &__header {
    display: flex;
    justify-content: space-between;
   

    &__title {
     
      font-size: 1.5rem;
      font-weight: 600;
    }

    &__btn-container {
      display: flex;
      align-content: stretch;
    //  background-color: #DD0C4E;
      // background-image:linear-gradient(to right, #29C2B6, #2CD97B );
     
      Button:hover{
        background: #1C1850;
      }
      
      

      @media (max-width: 900px) {
        flex-direction: column;
      }

      &__btn {
        display: flex;
        flex-direction: row-reverse;
        border: 1px solid black;
        border-radius: 5px;
        width: fit-content;
        padding: 5px;
        margin: 1%;
        background-color: #DD0C4E;
        // background-image:linear-gradient(to right, #29C2B6, #2CD97B );
        
      }

      &__btn > span {
        margin-right: 10px;
      }
    }
  }

  &__dts-tbl {
    margin-top: 20px;
    margin-right: 8%;

    &__label {
      font-size: 0.8rem;
      font-weight: 400;
      color: #8f8f8f;
    }

    &__value {
      font-size: 1rem;
      font-weight: 600;
    }

    &__line-break {
      margin: 10px 0px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    }
  }

  &__form {
    margin-top: 20px;

    &__label {
      align-self: flex-start;
      font-weight: 600;
      font-size: 1rem;
    }

    @media screen and (max-width: 600px) {
      position: static !important;
    }
  }
}

.profileDetails {
  max-width: 40%;
  margin-left: 10px;

  .installationContainer {
    border: 2px solid rgba(0, 0, 0, 0.06);
    height: 40px;
    margin-top: 5%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    box-sizing: border-box;
    border-radius: 6px;

    .installation {
      text-decoration: underline !important;
      text-decoration-color: #4090cc !important;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 0.813rem;
      line-height: 18px;
      text-align: center;
      color: #4090cc;
    }
  }

  @media screen and (max-width: 600px) {
    max-width: 100%;
    padding: 10px;
  }
}

@media screen and (min-width: 600px) and (max-width: 1024px) {
  .subLicenseHeader {
    border-right: 0px !important;
    border-left: 0px !important;
  }
}

@media screen and (min-width: 1320px) {
  
  .license-plan{
    // margin-left:650px;
    // margin-top: -545px;
  }
  .org-container{
    // margin-left:-75px;
  }
  
.div-container-layout{
 margin-left: 13em;
}
}
@media screen and (min-width: 1440px) {
  .div-container-layout{
    margin-left: 14em;
   }
  .license-plan{
    // margin-left:680px;
    // margin-top: -570px;
  }
  .org-container{
   
    // height: 570px;
    
    // padding-bottom: 100px;
    // margin-left:-75px;
  }
}
@media screen and (min-width: 1520px) {
  
  .license-plan{
    // margin-left:750px;
  }
  .org-container{
    // margin-left:-75px;
  }
  .div-container-layout{
    margin-left: 16em;
   }

}
@media screen and (min-width: 1620px) {

  .div-container-layout{
    margin-left: 21em;
   }

}
@media screen and (min-width: 1920px) {
  .profile-dts-container {
    margin-left: 20px;
  
  }
  .div-container-layout{
    margin-left: 31em;
   }
  .license-plan{
    // margin-left:1100px;
  }
  .org-container{
    // padding-left: 20px;
    // width: max-content;
    // margin-left:10px;
    // height:600px;
  }

}

@media screen and (min-width: 2560px) {
  .profile-dts-container {
    margin-left: 20px;
  
  }
  .license-plan{
    // margin-left:1400px;
  }
  .org-container{
    // margin-left:60px;
  }
  .div-container-layout{
    margin-left: 52em;
   }

}
.div-footer-profile{
  margin-top: 275px;
  margin-left: 15em;
 
  .ant-typography{
   color: #000;
}
    // background-color: yellow;
}
@media screen and (min-width: 320px) and (max-width: 600px) {
  .profile-view {
    margin-top: 5px;
    margin-bottom: 5px;
    align-items: center;
    margin-left: -1px;
    width: 320px;
}
.license-plan{
margin-left: 0px;
}
.profile-dts-container {
  margin-bottom: 10%;
  width: 95%;
}
.profile-view-install-instructions {
  width: 95%;
  margin-left: 0;
}
  .subHeaderProfile {
    border-right: 0px !important;
    border-left: 0px !important;
  }
  .div-footer-profile{
    margin-top: 275px;
    margin-left: 20.5%;
  }
}
@media screen and (min-width: 600px) and (max-width: 900px) {
  .profile-view {
    margin-top: 5px;
    margin-bottom: 5px;
    align-items: center;
    margin-left: -0px;
    width: 320px;
    // flex-direction: column;
}

  .div-footer-profile{
    margin-top: 275px;
    margin-left: 20%;
  }
}
