@import "./variables/dimensions";

.padding-small {
    padding: $padding-small;
}

.padding-large {
    padding: $padding-large;
}

.padding-medium {
    padding: $padding-small;
}