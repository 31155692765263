.license-card{
  padding:30px;
margin-bottom: 20px;
// border: transparent  6px solid;
border-radius: 4px;
        border-width: 2px;
        border-style: solid;
        border-image:linear-gradient(to bottom right,#DD0C4E 10%,#2b2672 80%);
        border-image-slice: 1;
        -webkit-mask: /*4*/
        linear-gradient(#fff 0 0) padding-box,
        linear-gradient(#fff 0 0);
  //  border-top-left-radius: 10px;
  // -webkit-mask: /*4*/
  // linear-gradient(#fff 0 0), 
  // linear-gradient(#fff 0 0);
  // -webkit-mask-composite: xor; /*5'*/
  //         mask-composite: exclude; /*5*/


@media screen and (min-width: 1440px) {
width:310px
}
@media screen and (max-width: 900px) {
  width:320px;
  // margin-left: -2%;
  }
  
}