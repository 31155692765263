@import "../../style/variables/colors";
@import "../../style/variables/dimensions";

.episilia-base-card {
     border-radius: 10px;
  
//    color: #FFF;
color: #000;
    @media (min-width: 2560px) {
        width:400px;
    }
   
    &-outlined {
        border: 1px solid;
        background: none;

        &-primary {
            // border-color: $primaryColor;
            // border-width: 3px;
            // border-style: solid;
            // border-radius: 6px;
            // border-color: #1C1850;
            border-radius: 4px;
            border-width: 2px;
            border-style: solid;
            border-image:linear-gradient(to bottom right,#DD0C4E 10%,#2b2672 80%);
            border-image-slice: 1;
            -webkit-mask: /*4*/
              linear-gradient(#fff 0 0) padding-box,
              linear-gradient(#fff 0 0);
        }

        &-secondary {
            border-color: $secondaryColor;
        }
    }

    &-filled {
        border: none;

        &-primary {
            //  background-image:linear-gradient(to top right, #006675 5% ,#2FA46E 50%,#30A56E 40%);
            // color:#FFF
            // background-image: linear-gradient( #DD0C4E 50% ,#1C1850 80%);
            // background-color: #fff;
            // border-radius: 8px;
            // border: #1C1850 3px solid;
            // color: #fff;
            // // background: linear-gradient(to right, red, orange);
            // border-width: 2px;
            border-radius: 4px;
            border-width: 2px;
            border-style: solid;
            border-image:linear-gradient(to bottom right,#DD0C4E 10%,#2b2672 80%);
            border-image-slice: 1;
            -webkit-mask: /*4*/
              linear-gradient(#fff 0 0) padding-box,
              linear-gradient(#fff 0 0);

        }

        &-secondary {
            background: $secondaryGradient;
        }

    }
        &-filledoutlined {
            border: 1px black solid;
            &-newcard {
                background: #95F3C1;
            }
    }

    &-floating {
        border: 1px solid;
        background: none;
        border-color: $gray;
        box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.25);
    }
}