.logo{
  margin-left: -1.9em;
  // width:100%;
// margin-right: auto;
  margin-top:-15vh;
 // width: 150px;
 height:15em;
width:15em;

 @media screen and (min-width:1360px) {
  margin-left: -0.95em;
 }
 @media screen and (min-width:2560px) {
  margin-left: -0.4em;
 }
}