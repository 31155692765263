@import "../../../../components/style/variables/colors";

.background-support{
    height: 102vh;
    position: absolute;
    margin-top: -5em;
    width: 95vw;
    margin-left: 4em;
    @media screen and (min-width:1300px){
        width: 96vw;
    }
    @media screen and (min-width:1500px){
        width: 96.5vw;
    }
    @media screen and (min-width:1920px){
        width: 97vw;
        // margin-top: -5em;
    }
    @media screen and (min-width:2560px){
        width: 98vw;
        // margin-top: -5em;
    }
}

.support-base-container {
    display: flex;
    flex-direction: row;
    margin-top: 30vh;
    border: none;
    margin-left: 15em;
    width: 840px;
 
    
    h4 {
        // color: #000;
    }
    .ant-card {
        // background-image: linear-gradient(to top right, #006675 5%, #2fa46e 50%, #30a56e 40%);
       
        // color: #000;
        
        // border: #1C1850 solid 2px;
        border-radius: 4px;
        border-width: 2px;
        border-style: solid;
        border-image:linear-gradient(to bottom right,#DD0C4E 10%,#2b2672 80%);
        border-image-slice: 1;
        -webkit-mask: /*4*/
        linear-gradient(#fff 0 0) padding-box,
        linear-gradient(#fff 0 0);
    }
    .ant-btn-primary {
        // background-image: linear-gradient(to right, #29c2b6, #2cd97b);
        background-color: #DD0C4E;
         border: #DD0C4E;
        border-radius: 3px;
        color: #fff;
        &:hover{
            background-color: #1C1850 ;
        }
    }

    .ant-card {
        margin-right: 20px;
        // border-radius: 10px;
    }
    @media screen and (min-width:1300px){
        // margin-left: 250px;
    }
    @media screen and (min-width:1420px){
         margin-left: 17em;
    }
    @media screen and (min-width:1520px){
        // margin-left: 380px;
        margin-left: 23em;
    }
    @media screen and (min-width:1620px){
        // margin-left: 380px;
        margin-left: 28em;
    }
    @media screen and (min-width:1920px){
        margin-left: 550px;
    }
    @media screen and (min-width:2560px){
        margin-left: 850px;
        }

}

.knowledge-base-form {
    width: 400px;
    font-family: Poppins;
    border: 1px solid #3bf0b3;
    text-align: center;

    .ant-card-head {
        // background-color: #e2fdf0;
        padding-left: 5px;
        padding-right: 0px;
        padding-top: 15px;
        font-size: 20px;
        border: #41e799 0px solid;
        color: #000;
    }

    .ant-card-body {
        color: #000;
        // background-color: #e2fdf0;
        border: #41e799 0px solid;
        border-top: none;
    }
}

.text-doc {
    font-size: 16px;
    background-color: $primaryColor;
    text-align: center;
    width: max-content;
    //border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    color: #000;
    border: $primaryColor 1px solid;
}

.form-support-query {
    width: 400px;
    // margin-left: 20px;
    font-family: Poppins;
    text-align: center;
    color: #000;

    border: 0px solid #41fbbd;
    .phone-icon {
        transform: rotate(90deg);
        margin-right: 10px;
    }

    .ant-card-head {
        // background-color: #FFF;
        padding-left: 5px;
        padding-right: 0px;
        font-size: 20px;
        padding-top: 15px;
        color: #000;
        border: 0px solid #41fbbd;
    }
    .ant-card-body {
        // background-color: #e2fdf0;
        border: 0px solid #41fbbd;
        text-align: left;
        margin-left: 70px;
        color: #000;
        padding-top: 20px;
        padding-bottom: 30px;
    }

    .table-contact-details {
        //   background-color: #e2fdf0;
        margin-left: 80px;
        margin-top: -20px;
    }
}


@media screen and (max-width:900px){
    .support-base-container {
    flex-direction: column;
     margin-left: 2em;
     width:320px;
     
@media screen and (min-width:500px) and (max-width:900px){
    margin-left: 25%;
}
    }
    .form-support-query {
        width: 300px;
        margin-top: 20px;
        .ant-card-body {
            padding-left: 5px;
            padding-right: 5px;
            margin-left: 10px;
        }
    }
    .knowledge-base-form {
        width: 300px;
        
    }
}