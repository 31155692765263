.graph-container{
    margin-left: auto;
    margin-right: auto;
    width: max-content;
    background-color: F2F4F7;
}

.div-background{
    //  background-image: linear-gradient(#cae6d0,#a9f1c1);
    // border: 1px green solid;
    padding-top: 30px;
    padding-bottom: 20px;
}


.graph-container-1{
    position:absolute;
    border: #a3a0a0 solid 1px;
    height: 375px;
    background-color: #FFF;
    min-width: 450px;
    height: 375px;
    margin-left: -20px;
    p{
        align-items: center;
        margin-top: 25%;
        margin-left: 25%;
    }
}

.graph-container-2{
    position:absolute;
    border: #a3a0a0 solid 1px;
    margin-top: 0px;
    margin-left: 48%;
    background-color: #FFF;
    width: 450px;
    height: 375px;
    p{
        align-items: center;
        margin-top: 25%;
        margin-left: 25%;
    }
}

.graph-container-3{
    position:absolute;
    border: #a3a0a0 solid 1px;
    width: 450px;
    background-color: #FFF;
    height: 375px;
    padding-right: 20px;
    margin-left: -20px;
    margin-top: 390px;
    p{
        align-items: center;
        margin-top: 25%;
        margin-left: 25%;
    }
    
}

.graph-container-4{
    position:absolute;
    border: #a3a0a0 solid 1px;
    // margin-top: -322px;
    margin-left: 48%;
    background-color: #FFF;
    width: 450px;
    height: 375px;
    margin-top: 390px;
    p{
        align-items: center;
        margin-top: 25%;
        margin-left: 25%;
    }
    margin-bottom: 20px;
    
}

.card-charts-container{
    height: 800px;
   background-color:#FFF;
    margin-left:auto;
    margin-right: auto;
    justify-content: center;
    background-image: none;

}

.card-body-charts{
    align-content: center;
    margin-left:auto;
    margin-right: auto;
    width:1300px;

}

.details-cluster{
    font-weight: 600;
    font-family: Poppins;
}

.details-cluster-value{
   
    font-family: Poppins;
    padding-left: 10px;
    text-transform: capitalize;
}



.title-graph{
font-family: Poppins;
font-weight: 500;
font-size: medium;
padding-left:10px;
padding-top: 15px;
}



