.footer{
    display: flex;
    justify-content: center;
    margin-top:40px;
    color: #C4C4C4;
    font-size:.8rem;
    @media screen and (min-width: 1920px) {
  
      margin-top:15%;
   
   }
}

@media only screen and (max-width: 600px) {
  .footer{
   margin-top:15%;
   margin-left: 5em;
   width: 250px;
  }
}

.footer-crm-screen{
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  font-family: Poppins;
  font-size: larger;
}