@import "../../style/variables/colors";


.btn{
  // background-color:$primaryColor;
  // background-image:linear-gradient(to right, #29C2B6, #2CD97B );
  background-color: #DD0C4E;
  border:none;
  border-radius: 3px;
  font-size: 15px;
  font-family: 'Poppins', sans-serif;
  height: 42px;
  color: $white;
  :hover {
    background: #1C1850;
    border-color: #1C1850;
    color:#fff
    // color:$primaryColor;
  }

  &Transparent{
    color:$black;
    background: none;
    border: 1px solid $black;
    &:hover {
      background: #1C1850;
      border-color: #1C1850;
      // color:$primaryColor;
    }
  }
}