@import '../../../../components/style/variables/colors';

.ant-table{
    border: none;
    
 }
.manage-users-table{
width:96.5%;
 margin-left: 0.5%;
//  margin-top: 4em;

 font-family: Poppins;
 border: none;

 tr:nth-child(even) {
    background-color: #e0e2e2;
}
 @media screen and (min-width:1680px) {
    height:81vh;
 }

 .ant-table{
    margin-left: auto;
    margin-right: auto;
    border: none;
    border-color: #DD0C4E;
   
 }

 h4{
    margin-top: 1em;
     font-weight: 600;
 }

// margin-right: auto;
.ant-table-tbody > tr > td{
    text-transform: none;
    width: 90px;
   
   
}
.ant-list-item{
    padding-left: -15px;
}
.ant-list-item-meta-description{
    font-size: 11px;
    padding-left: -5px;
}
}
.ant-table-content{
    border-radius: 4px;
    border-width: 2px;
    border-style: solid;
    border-image:linear-gradient(to bottom right,#DD0C4E 10%,#2b2672 80%);
    border-image-slice: 1;
}
.dark-table{

    .ant-table-tbody > tr > td{
        border-color: #282828;       
    }

    .ant-table{
       background-color: #282828;
        color:#DCDCDC;
    }


    .ant-table-scroll-horizontal{
        border: #000 1px solid;
        border-top-width: 0.5px ;
    }
     
     .ant-table-tbody > tr:hover > td {
        background: #3A3A3A;
        border-bottom-color:#000 1px solid ;
      }
      tr{
        background-color: #383838;
        color:#DCDCDC;
        border: #000 1px solid;
      }
    
      tr:nth-child(even) {
    background-color: #282828;
}
.ant-table-pagination > *{
    background-color: transparent;
    color:#DD0C4E;
    border-color: #fff;
    li{
      background-color: transparent;
      color:#DD0C4E;
  }
      }

.ant-pagination-item-link{
    background-color: #282828;
      color:#DD0C4E;
}
}

.manage-user-getinvite-button{
    
    // background-image:linear-gradient(to right, #29C2B6, #2CD97B ); 
    width: 200px;
}
.company-invite{
    margin-bottom: 20px;
    .ant-input{
        width:250px;
    }
}
.label-email{
    font-family: Poppins;
}
.label-role{
    font-family: Poppins;
    margin-left: 55%;
    margin-top: -20px;
}
.send-invite-div{
display: flex;
justify-content: space-between;
font-family: Poppins;
margin-top: 5px;
.ant-btn{
    border: none;
    color: #fb5757;
}
.ant-input{
    width:250px;
}
}
.manage-user-button-remove{
background-color: #FFF;
color:#F68181;
font-family: Poppins;
font-size: 13px;

}

.send-invite-input{
width: 200px;
font-family: Poppins;
}
.send-invite-radio{
font-family: Poppins;
}

.table-list-users{
    font-family: Poppins;
    // margin-left: 4em;
    .ant-tag{
        border: none;
    }
    .ant-switch-checked{
        background-color: $primaryColor;
    }
    .ant-table-tbody > tr > td {
        text-align: center;
        border: none;
        text-transform: none;
    }
 

}

.role-selection-drop-down{
 border: none;
 padding: 5px;
}


.active-selection-switch{
    border: none;
    padding: 5px;
}

.container-manage-users{
//background-color: #3636fb;    
margin-left: 75px;
margin-top: 40px;
min-height: 100vh;

.ant-table-thead > tr > th{
    width: 90px;
// background-color: #016775 ;
background-color: #1C1850;
// background-image: linear-gradient(180deg, #DD0C4E 20%, #1C1850 100%);
border: none;
color: #FFF;
}
@media screen and (min-width:1300px){
   
}
@media screen and (min-width:1420px){
  
}
@media screen and (min-width:1920px){

}
@media screen and (min-width:2560px){
    }
}
.button-invite-token{
    margin-left: 10px;
    background-color: #FFF;
    border: 0.5px #DAD8D4 solid;
    color: #3A3A3A ;
   

}
.tag-invite-token{
background-color: #FFF;
border: none;
.ant-btn-primary{
    background-color: #FFF;
    border: none;
    color: #3A3A3A ;


}
}
.popover-invite-token{

}

.modal-adduser{
    .manage-user-button-addrow{
        
        margin-top: 10px;
        background-color: transparent;
        // background-image:linear-gradient(to right, #29C2B6, #2CD97B ); 
        color:#3f25ff;
        font-family: Poppins;
        border: none;
        font-size: 13px;
      
    }
    .ant-modal-header{
        // background-color: #016775;
        font-family: Poppins;
        background-color: #1C1850;  
    }
    .ant-modal-title{
        color:#fff;
    }
    
    .ant-modal-content
    {
        width:600px;
        svg{
            color:#fff;
        }

    }
   
    .ant-btn-primary{
        // background-image:linear-gradient(to right, #29C2B6, #2CD97B ); 
        background-color: #DD0C4E;
        color: #FFF;
        font-family: Poppins;
        border-radius: 3px;
        border: #DD0C4E;


        &:hover{
            background-color: #1C1850;
        }
    }  
}
h3{
    font-family: Poppins;
}

.manage-user-button{
    background-color: $primaryColor;
    color: black;
    font-family: Poppins;
    margin-top: 20px;
    width: 200px;
    .UIButton:hover{
        color: #FFF;
        background-color: #1C1850;
    }

}

#Adduser{

    span{
        font-size: 17px;
        padding-top: -3px;
        margin-top: -4px;
        color: #FFF;
        margin-left: -15px;
        margin-right: -15px;
        padding-left:  10px;
        padding-right: 10px;
    }
    Button{
        border-radius: 3px;
        background-color: #DD0C4E;
        // background-image:linear-gradient(to right, #29C2B6, #2CD97B ); 
        color: #FFF;
        width: fit-content;
        font-family: Poppins;
        margin-left: 84%;
        position:relative;
        margin-top: 6em;
        border: none;
        // padding-bottom: 5px;
      
        
        :hover{
            background-color: #1C1850;
            color:#FFF;
            border-radius: 3px;
        }
        @media (min-width:320px) and (max-width:900px) {
            margin-left: 58%;
           }
        @media screen and (min-width:1300px){
            margin-left: 87%;
        }
        @media screen and (min-width:1420px){
            margin-left: 87.5%;
        }
        @media screen and (min-width:1520px){
            margin-left: 88%;
        }
        @media screen and (min-width:1920px){
            margin-left: 90%;
        }
        @media screen and (min-width:2560px){
            margin-left: 91%;
            }

    }

}
.div-users-footer{
    margin-top: 40vh;
    // background-color: #29C2B6;
}
// .manage-user-button-add{
    
//     // background-image:linear-gradient(to right, #29C2B6, #2CD97B ); 
//     color: black;
//     font-family: Poppins;
//     margin-left: 79%;
//     border:none;
    
// }
